import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import { Link } from 'react-router-dom';
import { getDocument, getSingleDocument, resetFolderDetails } from '../../store/Actions/Document';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import JsFileDownloader from 'js-file-downloader';

const DocumntsList = props => {
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';
  const [active, setActive] = useState('All');
  const [documents, setDocuments] = useState([]);
  const header = ['Document Name', 'Document Date', 'Description', 'Attachment'];

  let columns = [
    {
      name: 'File Name',
      selector: 'docname',
      sortable: true,
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          role="link"
          to={{ pathname: `/document/${house_id}`, state: { house_id: house_id } }}
        >
          {row.docname}
        </Link>
      ),
    },
    {
      name: 'Upload Date',
      selector: 'date',
      sortable: true,
      cell: row => Util.dateFormat(row.date),
    },
    { name: 'Description', selector: 'description', sortable: true },
    { name: 'Category', selector: 'category', sortable: true },
    {
      name: 'View Document ',
      selector: 'attachment',
      sortable: false,
      cell: row =>
        row.attachment &&
        row.attachment !== 'undefined' && (
          <div onClick={() => window.open(row.attachment, '_blank')}>
            <i
              className="glyphicon glyphicon-eye-open primary btn-lg addNewItemlogo1232"
              value={row.attachment}
            ></i>
          </div>
        ),
    },
    {
      name: 'Action',
      selector: '',
      sortable: false,
      cell: row =>
        row.attachment &&
        row.attachment !== 'undefined' && (
          <i
            className="glyphicon glyphicon-download-alt primary btn-lg addNewItemlogo1232"
            value={row.attachment}
            onClick={() => downloadFile(row.attachment)}
          ></i>
        ),
    },
  ];

  useEffect(() => {
    if (props.documents) {
      let doc = [];
      for (let i = 0; i < props.documents.length; i++) {
        doc.push(props.documents[i]);
      }
      setDocuments(doc);
    }
  }, [props.documents]);

  const handleDocType = type => {
    setActive(type);
    let doc = [];
    if (props.documents && props.documents.length > 0) {
      for (let i = 0; i < props.documents.length; i++) {
        if (type === 'All' || props.documents[i]['category'] == type) {
          doc.push(props.documents[i]);
        }
      }
    }
    setDocuments(doc);
  };

  const addNewFolderOrFile = () => {
    props.resetFolderDetails();
    props.history.push({
      pathname: `/document/${house_id}`,
      state: { house_id: house_id },
    });
  };

  // download Document //
  const downloadFile = items => {
    if (items.name !== undefined) {
    }
    const fileUrl = items;
    new JsFileDownloader({
      url: fileUrl,
    });
  };

  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Documents</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Documents)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row top-bar">
            <div className="col-md-12">
              <span
                className={active === 'All' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('All')}
              >
                All
              </span>
              <span
                className={active === 'Home Documents' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Home Documents')}
              >
                Home
              </span>
              <span
                className={active === 'Personal' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Personal')}
              >
                Personal
              </span>
              <span
                className={active === 'Others' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Others')}
              >
                Other
              </span>
            </div>
          </div>

          <Table
            header={header}
            url={'/document'}
            columns={columns}
            getSingleData={props.getSingleDocument}
            tableId="documents"
            data={documents}
            house_id={house_id}
          />
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12 right-button">
          <button className="btn btn-secondary btn-sm" onClick={addNewFolderOrFile}>
            <span className="glyphicon glyphicon-plus"> </span> Add Folder/File
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  documents: state.Document.documents.data,
  isLoading: state.Spinner.isDocumentLoading,
});

const mapDispatchToProps = {
  getDocument,
  getSingleDocument,
  resetFolderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumntsList);
