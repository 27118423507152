import React, { Component } from 'react';
import './App.css';
import Router from './components/router';
import ErrorBoundary from './components/error/error-boundary';

class App extends Component {
  ErrorFallback = ({ error, componentStack, resetErrorBoundary }) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <pre>{componentStack}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  };
  render() {
    return (
      <div className="container">
        <ErrorBoundary FallbackComponent={this.ErrorFallback}>
          <Router />
        </ErrorBoundary>
      </div>
    );
  }
}

export default App;
