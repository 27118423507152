import React from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import { Link } from 'react-router-dom';
import { getWarranty, getSingleWarranty } from '../../store/Actions/Warranty';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const WarrantyList = props => {
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';

  const header = [
    'Product Name',
    'Installation Date',
    'Company Name',
    'Contact Number',
    'Warranty Details',
    'Product Price',
    'Installation Charges',
    'Warranty End Date',
  ];
  var columns = [
    {
      name: 'Product Name',
      selector: 'product_name',
      sortable: true,
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          role="link"
          to={{ pathname: `/provider/${house_id}`, state: { house_id: house_id } }}
        >
          {row.product_name}
        </Link>
      ),
    },
    {
      name: 'Installation Date',
      selector: 'installation_date',
      sortable: true,
      cell: row => Util.dateFormat(row.installation_date),
    },
    { name: 'Company Name', selector: 'installation_company_name', sortable: true },
    { name: 'Contact Number', selector: 'contact_number', sortable: true },
    {
      name: 'Warranty Details',
      selector: '',
      sortable: true,
      cell: row => <span className="glyphicon glyphicon-tasks"></span>,
    },
    { name: 'Product Price', selector: 'product_price', sortable: true },
    { name: 'Installation Charges', selector: 'installation_charges', sortable: true },
  ];

  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Warranty Details List</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Warranties)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <Table
            header={header}
            url={'/provider'}
            columns={columns}
            getSingleData={props.getSingleWarranty}
            tableId={'waranty-' + house_id}
            data={props.warranties}
            house_id={house_id}
          />
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12 right-button">
          <Link
            to={{
              pathname: `/provider/${house_id}`,
              state: { house_id: house_id },
            }}
            className="btn btn-secondary btn-sm"
          >
            <span className="glyphicon glyphicon-plus"> </span> Add New Warranty
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  warranties: state.Warranty.warranties.data,
  isLoading: state.Spinner.isWarrantyLoading,
});

const mapDispatchToProps = {
  getWarranty,
  getSingleWarranty,
};

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyList);
