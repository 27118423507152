import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getSingleReference, getUserReference } from '../../store/Actions/Reference';
import '../../style/account.css';
import Table from '../../Reusable/Table';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const Referral = props => {
  const handleTabs = tab => {
    if (tab === 'personal') {
      props.history.push('/personal');
    } else if (tab === 'referral') {
      props.history.push('/referral');
    } else if (tab === 'subs') {
      props.history.push('/subscription');
    }
  };

  const header = [
    'Referred Date',
    'Referral Name',
    'Referral Phone',
    'Email Address',
    'Registered On',
  ];
  var columns = [
    {
      name: 'Referred Date',
      selector: 'referred_date',
      sortable: true,
      // cell: row => <Link data-tag="allowRowEvents" role="link" to={{pathname : "add-referal"}}>{row.referred_date}</Link>
    },
    { name: 'Referral Name', selector: 'name', sortable: true },
    { name: 'Referral Phone', selector: 'phone', sortable: true },
    { name: 'Email Address', selector: 'email', sortable: true },
    { name: 'Registered On', selector: 'registered_on', sortable: true },
  ];

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Account Details</span>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row top-bar">
            <div className="col-md-12 text-align-left">
              <span className="mr-50" onClick={e => handleTabs('personal')}>
                Personal Information
              </span>
              <span className="mr-50 active-bar" onClick={e => handleTabs('referral')}>
                Referrals
              </span>
              <span className="mr-50" onClick={e => handleTabs('subs')}>
                Subscription
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row pt-pb-10">
                <div className="col-md-12">
                  <p className="ml-60">
                    Here are the details of the people you have referred in the past.
                  </p>
                  <Table
                    header={header}
                    columns={columns}
                    url={'/add-referal'}
                    getSingleData={props.getSingleReference}
                    tableId="referral"
                    data={props.references}
                    house_id={''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12 right-button">
          <Link
            to={{
              pathname: `/add-referal`,
            }}
            className="btn btn-secondary btn-sm"
            role="button"
          >
            <span className="glyphicon glyphicon-plus"> </span> Add New Referral
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  references: state.Reference.references.data,
  isLoading: state.Spinner.isUserLoading,
});

const mapDispatchToProps = {
  getSingleReference,
  getUserReference,
};

export default connect(mapStateToProps, mapDispatchToProps)(Referral);
