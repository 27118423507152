import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import {
  getInsurance,
  getSingleInsurance,
  resetInsuranceDetails,
} from '../../store/Actions/insurance';
import { getContact } from '../../store/Actions/contact';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import { Link } from 'react-router-dom';

const InsuranceList = props => {
  useEffect(() => {
    if (props && props.insurances && props.insurances.length > 0) {
      console.log(props.insurances)
      if (props.contactList) {
        props.contactList.map((item1, index1) => {
          const selectedIndex = props.insurances.findIndex(
            val => item1.id === parseInt(val.provider.split('-')[0])
          );

          if (selectedIndex > -1 && selectedIndex !== undefined) {
            props.insurances[selectedIndex].provider_url = item1.url;
          }
        });
      }
    }
  }, [props.insurances]);
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';

  const header = [
    'Provider',
    'Insurance Number',
    'Effective Date',
    'Expiry Date',
    'Premium(Yearly)',
    'Provider URL',
    'Renewed',
    'Status',
    'Reminder Date',
  ];

  var columns = [
    {
      name: 'Provider',
      selector: 'provider',
      sortable: true,
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          role="link"
          to={{ pathname: `/insurance/${house_id}`, state: { house_id: house_id } }}
        >
          {row.provider.split('-')[1]}
        </Link>
      ),
    },
    { name: 'Insurance Number', selector: 'insurance_number', sortable: true },
    {
      name: 'Effective Date',
      selector: 'effective_date',
      sortable: true,
      cell: row => Util.dateFormat(row.effective_date),
    },
    {
      name: 'Expiry Date',
      selector: 'expiry_date',
      sortable: true,
      cell: row => Util.dateFormat(row.expiry_date),
    },
    { name: 'Premium(Yearly)', selector: 'premium', sortable: true },
    {
      name: 'Provider URL',
      selector: 'provider_url',
      sortable: true,
      cell: row => (
        <a
          href={row.provider_url}
          target="_blank"
          to={{ pathname: `/insurance/${house_id}`, state: { house_id: house_id } }}
        >
          {row.provider_url}
        </a>
      ),
    },
    { name: 'Renewed', selector: 'renewed', sortable: true },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: row =>
        row.status === 'Active' ? (
          <span className="active_status">{row.status}</span>
        ) : (
          <span className="expired_status">{row.status}</span>
        ),
    },
    { name: 'Reminder Date', selector: 'reminder_date', sortable: true },
  ];

  const addNewInsurance = () => {
    props.resetInsuranceDetails();
    props.history.push({
      pathname: `/insurance/${house_id}`,
      state: { house_id: house_id },
    });
  };

  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Insurance Details List</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Insurance)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <Table
            header={header}
            url={'/insurance'}
            columns={columns}
            getSingleData={props.getSingleInsurance}
            tableId="insurance"
            data={props.insurances}
            house_id={house_id}
          />
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-10"></div>
        <div className="col-sm-2 right-button">
          <button className="btn btn-secondary btn-sm" onClick={addNewInsurance}>
            <span className="glyphicon glyphicon-plus"> </span> Add New Insurance
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  insurances: state.Insurance.insurances.data,
  contactList: state.Contact.contacts.data,
  isLoading: state.Spinner.isInsuranceLoading,
});

const mapDispatchToProps = {
  getInsurance,
  getSingleInsurance,
  getContact,
  resetInsuranceDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceList);
