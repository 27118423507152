var commaNumber = require('comma-number');

const features = {
  Contacts: 'contacts',
  Loans: 'loans',
  Insurance: 'insurance',
  Transcation: 'transcation',
  Warranties: 'warranties',
  HomeCost: 'homecost',
  Leases: 'leases',
  Documents: 'documents',
  Links: 'links',
  Galleries: 'Galleries',
  Reminders: 'reminders',
  Reports: 'reports',
  Other: 'other',
};
const phoneTypes = {
  Mobile: 'Mobile',
  Work: 'Work',
  Home: 'Home',
  Fax: 'Fax',
  Other: 'Other',
};
const criticality = {
  Critical: 'critical',
  Major: 'high',
  Medium: 'medium',
  Minor: 'minor',
};
const linkCategories = {
  Home: 'Home',
  Personal: 'Personal',
  Other: 'Other',
};
const incidentStatus = {
  Open: 'Open',
  InProgress: 'InProgress',
  ReadyToTest: 'ReadyToTest',
  ValidationInProgress: 'ValidationInProgress',
  ReOpen: 'ReOpen',
  Validated: 'Validated',
  Completed: 'Completed',
  Duplicate: 'Duplicate',
  Admin: 'Admin',
};
const timetocontact = {
  '8AM-1PM': '8AM-1PM',
  '11AM-1PM': '11AM-1PM',
  '1PM-3PM': '1PM-3PM',
  '3PM-5PM': '3PM-5PM',
  '5PM-7PM': '5PM-7PM',
  'After 7PM': 'After 7PM',
};

const country = {
  Canada: {
    data: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon',
    ],
    currency: 'CAD',
  },

  UK: {
    data: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
    ],
    currency: 'GBP',
  },

  USA: {
    data: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'District of Columbia',
    ],
    currency: 'USD',
  },
};

export const InfoLinks = {
  Contacts: 'https://www.myhomemgmt.net/features#h.o7t23m8i3ug3',
  Insurance: 'https://www.myhomemgmt.net/features#h.3aw94z301hwi',
  Loans: 'https://www.myhomemgmt.net/features#h.g3gs42leubhx',
  ShareProperty: 'https://www.myhomemgmt.net/features#h.mrnul5ejmbx4',
  Lease: 'https://www.myhomemgmt.net/features#h.19ylq13jqy03',
  Documents: 'https://www.myhomemgmt.net/features#h.fte6rbmvnil0',
  Links: 'https://www.myhomemgmt.net/features#h.47fyx2dyrben',
  Galleries: 'https://www.myhomemgmt.net/features#h.fte6rbmvnil0',
  Reports: 'https://www.myhomemgmt.net/features#h.6nbi2zsnvyzf',
  Warranties: 'https://www.myhomemgmt.net/features#h.7809jdpn2qy0',
  HomeCost: 'https://www.myhomemgmt.net/features#h.xfr5krjse52j',
  Reminders: 'https://www.myhomemgmt.net/features#h.1jhiggvswxzf',
  Transactions: 'https://www.myhomemgmt.net/features#h.jek2qqxcr3rq',
};

export const Util = {
  countryDetails() {
    return country;
  },
  featuresList() {
    return features;
  },
  criticalityList() {
    return criticality;
  },
  timetocontactList() {
    return timetocontact;
  },
  incidentStatusList() {
    return incidentStatus;
  },
  linkCategoryList() {
    return linkCategories;
  },
  phoneTypelist() {
    return phoneTypes;
  },
  addCommas(nStr) {
    nStr = nStr.toString();
    var data = nStr.split(',');
    data = data.join('');
    nStr = data;
    var result1 = commaNumber(nStr);
    return result1;
  },

  addCommasList(nStr) {
    nStr = nStr.toString();
    var data = nStr.split(',');
    data = data.join('');
    const dec = data.split('.')[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    data = Number(data).toFixed(len);
    nStr = data;
    var result1 = commaNumber(nStr);
    return result1;
  },

  removeCommas(nStr) {
    nStr = nStr.toString();
    var data = nStr.split(',');
    data = data.join('');
    return data;
  },

  loanAmount(num1, num2) {
    num1 = num1.split(',').join('');
    num2 = num2.split(',').join('');
    if (num1 && num2) {
      var diff = num1 - num2;
      var num3 = this.addCommas(diff.toString());
      return num3;
    }
  },

  rateOfInterest(num) {
    var res = '0.000';
    num = num.split('%')[0];
    if (num) {
      var x = num.split('.');
      var x1 = x[0];
      var x2 =
        x.length > 1 ? '.' + (x[1].length > 3 ? x[1][0] + '' + x[1][1] + x[1][2] : x[1]) : '.000';
      res = x1 + x2;
    }
    return res + '%';
  },

  getCurrentDate(separator = '') {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${
      date < 10 ? `0${date}` : `${date}`
    }`;
  },

  getLoggedinUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user;
  },
  isNumericInput: event => {
    const key = event.keyCode;
    return (
      (key >= 48 && key <= 57) || // Allow number line
      (key >= 96 && key <= 105) // Allow number pad
    );
  },
  isModifierKey: event => {
    const key = event.keyCode;
    return (
      event.shiftKey === true ||
      key === 35 ||
      key === 36 || // Allow Shift, Home, End
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 46 || // Allow Backspace, Tab, Enter, Delete
      (key > 36 && key < 41) || // Allow left, up, right, down
      ((event.ctrlKey === true || event.metaKey === true) &&
        (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
    );
  },
  dateFormat(date) {
    if (!date) {
      return '';
    }
    const d1 = new Date(date).toDateString();
    const d2 = d1.split(' ');
    let result = `${d2[1]} ${d2[2]} ${d2[3]}`;
    return result;
  },
  dateFormatWithTime(date) {
    if (!date) {
      return '';
    }
    const options = {
      day: 'numeric',
      year: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: 'numeric',
    };
    let d1 = new Date(date).toString();
    let d2 = d1.split(' ');
    let result = d2[1].split(',')[0] + ' ' + d2[0] + ' ' + d2[2] + ' ' + d2[3] + ' ' + d2[4];
    return result;
  },

  enforceFormat: event => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!Util.isNumericInput(event) && !Util.isModifierKey(event)) {
      event.preventDefault();
    }
  },

  formatToPhone: event => {
    if (Util.isModifierKey(event)) {
      return;
    }
    // I am lazy and don't like to type things more than once
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) {
      target.value = `${zip}-${middle}-${last}`;
    } else if (input.length > 3) {
      target.value = `${zip}-${middle}`;
    } else if (input.length > 0) {
      target.value = `${zip}`;
    }
  },

  formatReset: event => {
    if (Util.isModifierKey(event)) {
      return;
    }
    const target = event.target;
    const input = event.target.value.replace(/\D/g, '');
    if (input.length > 0) {
      target.value = `${input}`;
    }
  },

  openInNewTab: url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  },

  getFutureDate: (date, renewed) => {
    var chooseDate = new Date(date);
    if (renewed === 'Yes') {
      chooseDate.setMonth(chooseDate.getMonth() + 12);
    }
    const year = chooseDate.getFullYear();
    const month = ('0' + (chooseDate.getMonth() + 1)).slice(-2);
    const day = ('0' + chooseDate.getDate()).slice(-2);
    const futureDate = `${year}-${month}-${day}`;
    return futureDate;
  },

  validateDates: (startdt, enddt) => {
    if (!startdt || !enddt) {
      return false;
    }
    let stDate = new Date(startdt);
    let endDate = new Date(enddt);
    stDate = Math.floor(stDate.getTime() / 86400000);
    endDate = Math.floor(endDate.getTime() / 86400000);
    if (stDate > endDate) {
      return false;
    }
    return true;
  },
  monthDiff: (startDate, endDate) => {
    let months;
    let d1 = new Date(startDate);
    let d2 = new Date(endDate);
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  },
  dayDiff: (startDate, endDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  },
  getFileNameFromUrl: fileName => {
    if (!fileName) return null;
    const lastIndex = fileName.lastIndexOf('/');
    const newFileName = fileName.substring(lastIndex + 1);
    return newFileName;
  },
  getEstimatedLoanTansaction: (loanData, principalPaid) => {
    if (!loanData) return null;
    const loanamount = loanData['loanamount'];
    const rateofinterest = parseFloat(
      loanData['renewal_intrest_rate']
        ? loanData['renewal_intrest_rate']
        : loanData['rateofinterest']
    );
    const loanterminyears = loanData['loanterm'];
    const mroi = rateofinterest / (12 * 100);
    const prpaid = parseInt(Util.removeCommas(principalPaid));

    const loa = parseInt(Util.removeCommas(loanamount)) - prpaid;
    const term = parseInt(loanterminyears * 12);
    const payment = loa / ((Math.pow(1 + mroi, term) - 1) / (mroi * Math.pow(1 + mroi, term)));
    const interest = loa * mroi;
    const principal = payment - interest;
    const emiDetails = {
      loanamount: parseFloat(loa).toFixed(2),
      payment: parseFloat(payment).toFixed(2),
      interest: parseFloat(interest).toFixed(2),
      principal: parseFloat(principal).toFixed(2),
      balanceloan: parseFloat(loa - principal).toFixed(2),
    };
    return emiDetails;
  },
};
