import {
  ADD_INCIDENT,
  GET_INCIDENT,
  GET_INCIDENT_DETAILS,
  INCIDENT_STATUS,
  DELETE_INCIDENT,
} from '../actionTypes';

const initialState = {
  incidents: {},
  incidentDetails: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_INCIDENT:
      return {
        ...state,
        incidentDetails: action.payload,
      };
    case GET_INCIDENT:
      return {
        ...state,
        incidents: action.payload,
      };
    case GET_INCIDENT_DETAILS:
      return {
        ...state,
        incidentDetails: action.payload,
      };
    case DELETE_INCIDENT:
      return {
        ...state,
        incidents: action.payload,
      };
    case INCIDENT_STATUS:
      return {
        ...state,
        incidentDetails: {},
        status: action.payload,
      };
    default:
      return state;
  }
};
