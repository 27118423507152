import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import { useHistory } from 'react-router-dom';
import { getLink, getSingleLink, resetLinkDetails } from '../../store/Actions/Link';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import Table from '../../Reusable/Table';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const LinkList = props => {
  let history = useHistory();
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';

  const [gridData, setGridData] = useState([]);
  const [active, setActive] = useState('All');

  const header = ['Name', 'Description', 'Group'];
  var columns = [
    {
      name: 'Added Date',
      selector: 'date',
      sortable: true,
      cell: row => Util.dateFormat(row.date),
    },
    { name: 'Group', selector: 'groupname', sortable: true },
    { name: 'Description', selector: 'description', sortable: true },
    {
      name: 'Url Name',
      selector: 'urlname',
      sortable: true,
      cell: row => (
        <a href={row.urlname} target="_blank">
          {row.urlname}
        </a>
      ),
    },
    {
      name: 'Actions',
      selector: '',
      sortable: true,
      cell: row => (
        <a href="javascript:void(0)" onClick={() => handleEdit(row)}>
          <i className="glyphicon glyphicon-edit"></i>
        </a>
      ),
    },
  ];
  useEffect(() => {
    if (props.links && props.links.length > 0) {
      setGridData(props.links);
    }
  }, [props.links]);

  const handleEdit = row => {
    history.push({
      pathname: `/link/${house_id}`,
      state: { house_id: house_id },
    });
    var data = { id: row.id };
    props.getSingleLink(data);
  };

  const handleDocType = type => {
    setActive(type);
    let linkData = [];
    if (props.links && props.links.length > 0) {
      for (let i = 0; i < props.links.length; i++) {
        if (props.links[i]['category'] == type || type == 'All') {
          linkData.push(props.links[i]);
        }
      }
    }
    setGridData(linkData);
  };

  const addNewLink = () => {
    props.resetLinkDetails();
    props.history.push({
      pathname: `/link/${house_id}`,
      state: { house_id: house_id },
    });
  };

  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Links List</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Links)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row top-bar">
            <div className="col-md-12 text-align-left">
              <span
                className={active === 'All' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('All')}
              >
                All
              </span>
              <span
                className={active === 'Home' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Home')}
              >
                Home
              </span>
              <span
                className={active === 'Personal' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Personal')}
              >
                Personal
              </span>
              <span
                className={active === 'Other' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Other')}
              >
                Other
              </span>
            </div>
          </div>
          {gridData !== undefined && (
            <Table
              header={header}
              url={'/Link'}
              columns={columns}
              getSingleData={props.getSingleLink}
              tableId="Links"
              data={gridData}
              house_id={house_id}
            />
          )}
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12 right-button">
          <button className="btn btn-secondary btn-sm" onClick={addNewLink}>
            <span className="glyphicon glyphicon-plus"> </span> Add New Link
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  links: state.Link.links.data,
  isLoading: state.Spinner.isLinkLoading,
});

const mapDispatchToProps = {
  getLink,
  getSingleLink,
  resetLinkDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkList);
