import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";
import App from './App';
import { createStore, applyMiddleware } from "redux";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import allReducers from "./store/Reducers";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import Footer from './components/Footer';
const composeEnhancers = composeWithDevTools({
	__REDUX_DEVTOOLS_EXTENSION__: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
});
const store = createStore(allReducers, composeEnhancers(
	applyMiddleware(thunk)
));


ReactDOM.render(
  <Provider store={store}>
    <App />
    <Footer />
  </Provider>,

  document.getElementById("root")
);

serviceWorker.unregister();
