import { Incident } from '../../api/api';
import {
  GET_INCIDENT_DETAILS,
  GET_INCIDENT,
  INCIDENT_STATUS,
  DELETE_INCIDENT,
  INCIDENT_SPINNER,
} from '../actionTypes';
import { NotificationManager } from 'react-notifications';
import { ShowSpinner, HideSpinner } from '../commondispatch';

export const addIncident = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INCIDENT_SPINNER);
    await Incident.addIncident(data)
      .then(res => {
        if (res.status === 200) {
          var data = {
            type: GET_INCIDENT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        var status = {
          type: INCIDENT_STATUS,
          payload: res.status,
        };
        dispatch(status);
        HideSpinner(dispatch, INCIDENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INCIDENT_SPINNER);
        throw error;
      });
  };
};

export const getIncident = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INCIDENT_SPINNER);
    await Incident.getIncidents(data)
      .then(res => {
        if (res.status === 200) {
          var data = {
            type: GET_INCIDENT,
            payload: res,
          };
          dispatch(data);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, INCIDENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INCIDENT_SPINNER);
        throw error;
      });
  };
};

export const getIncidentById = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INCIDENT_SPINNER);
    await Incident.getIncidentById(data)
      .then(res => {
        if (res.status === 200) {
          var data = {
            type: GET_INCIDENT_DETAILS,
            payload: res,
          };
          dispatch(data);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, INCIDENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INCIDENT_SPINNER);
        throw error;
      });
  };
};

export const deleteIncident = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INCIDENT_SPINNER);
    await Incident.deleteIncident(data)
      .then(res => {
        if (res.status === 200) {
          var data = {
            type: DELETE_INCIDENT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        var status = {
          type: INCIDENT_STATUS,
          payload: res.status,
        };
        dispatch(status);
        HideSpinner(dispatch, INCIDENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INCIDENT_SPINNER);
        throw error;
      });
  };
};

export const clearIncidentStatus = data => {
  return dispatch => {
    var status = {
      type: INCIDENT_STATUS,
      payload: undefined,
    };
    dispatch(status);
  };
};
