export const ShowSpinner = (dispatch, type) => {
  dispatch({
    type: type,
    payload: true,
  });
};

export const HideSpinner = (dispatch, type) => {
  dispatch({
    type: type,
    payload: false,
  });
};
