import { Reference } from '../../api/api';
import { GET_REFERENCES, GET_SINGLE_REFERENCE, REFERENCE_SPINNER } from '../actionTypes';
import { ShowSpinner, HideSpinner } from '../commondispatch';
import { NotificationManager } from 'react-notifications';

export const addReference = data => {
  return async dispatch => {
    ShowSpinner(dispatch, REFERENCE_SPINNER);
    await Reference.addReference(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_REFERENCES,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, REFERENCE_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, REFERENCE_SPINNER);
        throw error;
      });
  };
};

export const getUserReference = data => {
  return async dispatch => {
    ShowSpinner(dispatch, REFERENCE_SPINNER);
    await Reference.getUserReference(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_REFERENCES,
            payload: res,
          };
          dispatch(data);
          var data = {
            type: GET_SINGLE_REFERENCE,
            payload: {},
          };
          dispatch(data);
        }
        HideSpinner(dispatch, REFERENCE_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, REFERENCE_SPINNER);
        throw error;
      });
  };
};

export const getSingleReference = data => {
  return async dispatch => {
    ShowSpinner(dispatch, REFERENCE_SPINNER);
    await Reference.getSingleReference(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_SINGLE_REFERENCE,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, REFERENCE_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, REFERENCE_SPINNER);
        throw error;
      });
  };
};
