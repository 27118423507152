import React from 'react';
import '../../style/authentication.css';
import leftImage from './features_whitebg.png';
import logo from './logo.png';
import { Link } from 'react-router-dom';

export default function Leftbar() {
  return (
    <React.Fragment>
      <div className="col-sm-12">
        <Link to="/">
          <img src={logo} className="logo-img" alt="logo" />
        </Link>
      </div>

      <div className="col-sm-12 hidden-sm hidden-xs">
        <img src={leftImage} className="login_home_img" width="100%" height="100%" alt=""/>
      </div>
    </React.Fragment>
  );
}
