import React from 'react';
import '../../style/Contact.css';
import { Link } from 'react-router-dom';
import { getShareById } from '../../store/Actions/Share';
import { connect } from 'react-redux';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const ShareList = props => {
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';
  const header = [
    'Company',
    'Contact Person',
    'Mobile No.',
    'Landline',
    'Email',
    'URL',
    'Address',
    'Group',
  ];

  var columns = [
    {
      name: 'Shared Name',
      selector: 'fname',
      sortable: true,
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          role="link"
          to={{ pathname: `/share/${house_id}`, state: { house_id: house_id } }}
        >
          {row.fname} {row.lname}
        </Link>
      ),
    },
    // { name: 'Last Name', selector: 'lname', sortable: true, },
    { name: 'Phone Number', selector: 'phono', sortable: true },
    { name: 'Email Id', selector: 'email', sortable: true },
    { name: 'Access Level', selector: 'accesslevel', sortable: true },
    { name: 'Action', selector: 'action', sortable: true },
  ];

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Share Property Details</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.ShareProperty)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <Table
            url={'/share'}
            data={props.share}
            columns={columns}
            header={header}
            getSingleData={props.getShareById}
            tableId={'contact' + house_id}
            house_id={house_id}
          />
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12 right-button">
          <Link
            to={{
              pathname: `/share/${house_id}`,
              state: { house_id: house_id },
            }}
            className="btn btn-secondary btn-sm"
            role="button"
          >
            <span className="glyphicon glyphicon-plus"> </span> Share Property
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  share: state.Share.shares.data,
  isLoading: state.Spinner.isShareLoading,
});

const mapDispatchToProps = {
  getShareById,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareList);
