import { Transaction } from '../../api/api';
import {
  ADD_TRANSACTION,
  GET_TRANSACTION,
  GET_TRANSACTION_All,
  GET_SINGLE_TRANSACTION,
  DELETE_TRANSACTION,
  UNDELETE_TRANSACTION,
  TRANSACTION_SPINNER,
} from '../actionTypes';
import { NotificationManager } from 'react-notifications';
import { ShowSpinner, HideSpinner } from '../commondispatch';

export const addTransaction = data => {
  return async dispatch => {
    ShowSpinner(dispatch, TRANSACTION_SPINNER);
    await Transaction.addTransaction(data)
      .then(res => {
        if (res.status === 200) {
          var data = {
            type: ADD_TRANSACTION,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, TRANSACTION_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, TRANSACTION_SPINNER);
        throw error;
      });
  };
};

export const getTransaction = data => {
  return async dispatch => {
    ShowSpinner(dispatch, TRANSACTION_SPINNER);
    await Transaction.getTransaction(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_TRANSACTION,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, TRANSACTION_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, TRANSACTION_SPINNER);
        throw error;
      });
  };
};

export const getTransactionAllData = data => {
  return async dispatch => {
    ShowSpinner(dispatch, TRANSACTION_SPINNER);
    await Transaction.getTransactionAllData(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_TRANSACTION_All,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, TRANSACTION_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, TRANSACTION_SPINNER);
        throw error;
      });
  };
};

export const getSingleTransaction = data => {
  return async dispatch => {
    ShowSpinner(dispatch, TRANSACTION_SPINNER);
    await Transaction.getSingleTransaction(data)
      .then(res => {
        if (
          res &&
          Object.keys(res).length > 0 &&
          (res.status === 200 || res.status === 404 || res.status === 422)
        ) {
          var data = {
            type: GET_SINGLE_TRANSACTION,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, TRANSACTION_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, TRANSACTION_SPINNER);
        throw error;
      });
  };
};

export const deleteTransaction = data => {
  return async dispatch => {
    ShowSpinner(dispatch, TRANSACTION_SPINNER);
    await Transaction.deleteTransaction(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: DELETE_TRANSACTION,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
          // return res;
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, TRANSACTION_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, TRANSACTION_SPINNER);
        throw error;
      });
  };
};
export const unDeleteTransaction = data => {
  return async dispatch => {
    ShowSpinner(dispatch, TRANSACTION_SPINNER);
    await Transaction.unDeleteTransaction(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: UNDELETE_TRANSACTION,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, TRANSACTION_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, TRANSACTION_SPINNER);
        throw error;
      });
  };
};

export const resetTransactionDetails = () => {
  return dispatch => {
    var data = {
      type: GET_SINGLE_TRANSACTION,
      payload: { data: null },
    };
    dispatch(data);
  };
};
