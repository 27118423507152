import React from 'react';
import '../App.css';



const Footer = () => {
    return(
        <div className="container-footer">
        <span className="version">V - 1.0.1</span>
        </div>
    )
}


export default Footer;