import {
  USER_SPINNER,
  HOUSE_SPINNER,
  LOAN_SPINNER,
  INSURANCE_SPINNER,
  INCIDENT_SPINNER,
  TRANSACTION_SPINNER,
  WARRANTY_SPINNER,
  ACCOUNT_SPINNER,
  REFERENCE_SPINNER,
  DOCUMENT_SPINNER,
  LINK_SPINNER,
  GALLERY_SPINNER,
  SHARE_SPINNER,
  LEASE_SPINNER,
  EVENT_SPINNER,
  CONTACT_SPINNER,
} from '../actionTypes';

const initialState = {
  isUserLoading: false,
  isHouseLoading: false,
  isLoanLoading: false,
  isInsuranceLoading: false,
  isIncidentLoading: false,
  isTransactionLoading: false,
  isWarrantyLoading: false,
  isAccountLoading: false,
  isReferenceLoading: false,
  isDocumentLoading: false,
  isLinkLoading: false,
  isGalleryLoading: false,
  isShareLoading: false,
  isLeaseLoading: false,
  isEventLoading: false,
  isContactLoading: false,
  isContactLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_SPINNER:
      return {
        ...state,
        isUserLoading: action.payload,
      };
    case HOUSE_SPINNER:
      return {
        ...state,
        isHouseLoading: action.payload,
      };
    case LOAN_SPINNER:
      return {
        ...state,
        isLoanLoading: action.payload,
      };
    case INSURANCE_SPINNER:
      return {
        ...state,
        isInsuranceLoading: action.payload,
      };
    case INCIDENT_SPINNER:
      return {
        ...state,
        isIncidentLoading: action.payload,
      };
    case TRANSACTION_SPINNER:
      return {
        ...state,
        isTransactionLoading: action.payload,
      };
    case WARRANTY_SPINNER:
      return {
        ...state,
        isWarrantyLoading: action.payload,
      };
    case ACCOUNT_SPINNER:
      return {
        ...state,
        isAccountLoading: action.payload,
      };
    case REFERENCE_SPINNER:
      return {
        ...state,
        isReferenceLoading: action.payload,
      };
    case DOCUMENT_SPINNER:
      return {
        ...state,
        isDocumentLoading: action.payload,
      };
    case LINK_SPINNER:
      return {
        ...state,
        isLinkLoading: action.payload,
      };
    case GALLERY_SPINNER:
      return {
        ...state,
        isGalleryLoading: action.payload,
      };
    case SHARE_SPINNER:
      return {
        ...state,
        isShareLoading: action.payload,
      };
    case LEASE_SPINNER:
      return {
        ...state,
        isLeaseLoading: action.payload,
      };
    case EVENT_SPINNER:
      return {
        ...state,
        isEventLoading: action.payload,
      };
    case CONTACT_SPINNER:
      return {
        ...state,
        isContactLoading: action.payload,
      };
    default:
      return state;
  }
}
