import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import notFound from './notFound';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { getHouseDetail } from '../store/Actions/house';
import { getGroup } from '../store/Actions/contact';
//import App from "../App";
import { connect } from 'react-redux';
import Signup from './Authentication/signup';
import Login from './Authentication/login';
import Forgot from './Authentication/forgot';
import ResetPassword from './Authentication/reset-password';
import HouseDetails from './House/House-details';
import HMOSpace from './House/HMO-space';
import TitleHolders from './House/Title-holders';
import Hoadetails from './House/Hoa-details';
import Realtordetails from './House/Realtor-details';
import Navbar from './navbar';
import Dashboard from './Dashboard/Dashboard';
import Contact from './Contacts/Contact';
import LoanList from './Loan/Loan-list';
import LoanDetails from './Loan/Loan-details';
import LenderDetails from './Loan/Lender-details';
import AdditionalDetails from './Loan/Additional-details';
import ContactList from './Contacts/Contact-list';
import Insurance from './Insurance/Insurance';
import Agent from './Insurance/Agent';
import Reminder from './Insurance/Reminder';
import InsuranceList from './Insurance/Insurance-list';
import Transaction from './Transaction/Transaction';
import TransactionList from './Transaction/Transaction-list';
import Amortization from './Record/Amortization';
import Warranty from './Warranty/Warranty';
import WarrantyList from './Warranty/Warranty-list';
import Installation from './Warranty/Installation';
import WarrantyDates from './Warranty/Warranty-dates';
import Provider from './Warranty/Provider';
import LoanTransaction from './Loan/Loan-transaction';
import Document from './Documents/documents';
import DocumntsList from './Documents/documents-list';
import Link from './Link/links';
import LinkList from './Link/link-list';
import Galary from './Gallery/gallery';
import Personal from './Account/Personal';
import Referral from './Account/Referral';
import Subscription from './Account/Subscription';
import Addreferral from './Account/Addreferral';
import ChangePassword from './Account/ChangePassword';
import Homecost from './Homecost/homecost';
/** Lease routing */
import Lease from './Lease/Lease';
import Tenant from './Lease/Tenant';
import Realtor from './Lease/Realtor-details';
import Hmo from './Lease/HMO-space';
import Additional from './Lease/Additional';
import LeaseList from './Lease/Lease-list';
import ShareList from './Shareproperty/Share-list';
import Share from './Shareproperty/Share';
import Report from './Report/Report';
import ReportList from './Report/Report-list';
import ReminderCalender from './Reminder/reminder';
import GenerateTransaction from './Contacts/GenerateTransaction';
import CreateUser from './Create-User/create-user';
import EditUser from './Create-User/edit-user';
import UserList from './Create-User/user-list';
import MailList from './Create-User/mailList';
import IncidentList from './Incident/incident-list';
import IncidentDetails from './Incident/incident';

const Router = props => {
  const baseRoutes = [
    'dashboard',
    'incident-list',
    'incident',
    'change-password',
    'add-referal',
    'create-user',
    'edit-user',
    'user-list',
    'personal',
    'house-details'
  ];
  let login = false;
  let user = {};
  let redirect = '';
  if ('user' in localStorage) {
    user = JSON.parse(localStorage.getItem('user'));
    const local_house_id = localStorage.getItem('house_id');
    if (user['email']) {
      login = true;
      if (user.housecount > 0) {
        const pathName = window.location.pathname.split('/');
        const routExists = baseRoutes.find(f => f === pathName[1]);
        const house_id = pathName[2];
        const isNotaNumber = !house_id || isNaN(house_id);
        redirect = !routExists && isNotaNumber ? '/dashboard' : '';
      } else {
        redirect = '/house-details';
      }
    }
  }

  useEffect(() => {
    props.getGroup();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        {login ? (
          <React.Fragment>
            <Navbar />
            <div className="container-body">
             
              <Route path="/incident-list" component={IncidentList} />
              <Route path="/incident" component={IncidentDetails} />
              <Route path="/change-password" component={ChangePassword} />
              <Route path="/add-referal" component={Addreferral} />
              <Route path="/create-user" component={CreateUser} />
              <Route path="/edit-user" component={EditUser} />
              <Route path="/user-list" component={UserList} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/personal" component={Personal} />
              <Route path="/generate-transaction/:id" component={GenerateTransaction} />
              <Route path="/remindercalender/:id" component={ReminderCalender} />
              <Route path="/loan-transaction/:id" component={LoanTransaction} />
              <Route path="/house-details/:id?" component={HouseDetails} />
              <Route path="/title-holders/:id" component={TitleHolders} />
              <Route path="/hoa-detail/:id" component={Hoadetails} />
              <Route path="/realtor-detail/:id" component={Realtordetails} />
              <Route path="/hmo-space/:id" component={HMOSpace} />
              <Route path="/contact/:id" component={ContactList} />
              <Route path="/contact-form/:id" component={Contact} />
              <Route path="/loan-list/:id" component={LoanList} />
              <Route path="/loan-details/:id" component={LoanDetails} />
              <Route path="/loan-lender/:id" component={LenderDetails} />
              <Route path="/insurance/:id" component={Insurance} />
              <Route path="/agent/:id" component={Agent} />
              <Route path="/reminder/:id" component={Reminder} />
              <Route path="/insurance-list/:id" component={InsuranceList} />
              <Route path="/transaction/:id" component={Transaction} />
              <Route path="/transaction-list/:id" component={TransactionList} />
              <Route path="/warranty/:id" component={Warranty} />
              <Route path="/warrantydates/:id" component={WarrantyDates} />
              <Route path="/installation/:id" component={Installation} />
              <Route path="/Warranty-list/:id" component={WarrantyList} />
              <Route path="/provider/:id" component={Provider} />
              <Route path="/referral/:id" component={Referral} />
              <Route path="/subscription/:id" component={Subscription} />
              <Route path="/document/:id" component={Document} />
              <Route path="/document-list/:id" component={DocumntsList} />
              <Route path="/link/:id" component={Link} />
              <Route path="/link-list/:id" component={LinkList} />
              <Route path="/gallary/:id" component={Galary} />
              <Route path="/mail-list/:id" component={MailList} />
              <Route path="/amortization/:id" component={Amortization} />
              <Route path="/loan-additionals/:id" component={AdditionalDetails} />
              <Route path="/lease/:id" component={Lease} />
              <Route path="/tenant/:id" component={Tenant} />
              <Route path="/realtor/:id" component={Realtor} />
              <Route path="/hmo/:id" component={Hmo} />
              <Route path="/additional/:id" component={Additional} />
              <Route path="/lease-list/:id" component={LeaseList} />
              <Route path="/homecost/:id" component={Homecost} />
              <Route path="/share-list/:id" component={ShareList} />
              <Route path="/share/:id" component={Share} />
              <Route path="/report-list/:id" component={ReportList} />
              <Route path="/report/:id" component={Report} />
              {redirect && <Redirect from="/" to={redirect} />}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Route path="/" exact component={Login} />
            <Route path="/forgot" component={Forgot} />
            <Route path="/signup" component={Signup} />
            <Route path="/reset-password" component={ResetPassword} />
            <Redirect from="*" to="/" />
          </React.Fragment>
        )}
        <Route path="*" exact={true} component={notFound} />
      </Switch>
      <NotificationContainer />
    </BrowserRouter>
  );
};

const mapStateToProps = state => ({
  user: state.Authentication.user,
  houseDetails: state.House.houseDetail.data,
});

const mapDispatchToProps = {
  getHouseDetail,
  getGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
