import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { addIncident, deleteIncident, getIncidentById } from '../../store/Actions/Incident';
import { connect } from 'react-redux';
import { Util } from '../../Datamanipulation/Util';
import S3 from 'aws-s3';
import JsFileDownloader from 'js-file-downloader';
import config from '../../config';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import { sortBy } from 'lodash';
import ConfirmModal from '../../Reusable/Confirm-Modal';

const IncidentDetails = props => {
  // aws-s3 uploader//
  const s3Config = config.REACT_APP_S3_CLIENT_CONFIG;
  const bucketName =
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user')).bucket_folder_name;
  s3Config.dirName = `${bucketName}/Incidents`;

  const S3Client = new S3(s3Config);
  const generate_random_string = string_length => {
    let random_string = '';
    let random_ascii;
    let ascii_low = 65;
    let ascii_high = 90;
    for (let i = 0; i < string_length; i++) {
      random_ascii = Math.floor(Math.random() * (ascii_high - ascii_low) + ascii_low);
      random_string += String.fromCharCode(random_ascii);
    }
    return random_string;
  };

  const [featurename, setFeatureName] = useState('');
  const [title, setTitle] = useState('');
  const [criticality, setCriticality] = useState('');
  const [description, setDescription] = useState('');
  const [referenceurl, setReferenceUrl] = useState('');
  const [imagepath, setImagePath] = useState('');
  const [previewImage, setPreviewImage] = useState('../assets/image/dummy.png');
  const [contactphone, setContactPhone] = useState('');
  const [timetocontact, setTimeToContact] = useState('');
  const [incidentid, setId] = useState('');
  const [status, setStatus] = useState('Open');
  const [isOpen, setIsopen] = useState(false);

  const userDetails = JSON.parse(localStorage.getItem('user'));
  const userrole = userDetails.role;

  useEffect(() => {
    if (props.incidentStatus === 200) {
      props.history.push('/incident-list');
    }
    if (props.incidentDetails && props.incidentDetails.length > 0) {
      setFeatureName(props.incidentDetails[0].featurename);
      setTitle(props.incidentDetails[0].title);
      setCriticality(props.incidentDetails[0].criticality);
      setDescription(props.incidentDetails[0].description);
      setReferenceUrl(props.incidentDetails[0].referenceurl);
      setImagePath(props.incidentDetails[0].imagepath);
      setContactPhone(props.incidentDetails[0].contactphone);
      setTimeToContact(props.incidentDetails[0].timetocontact);
      setId(props.incidentDetails[0].id);
      setStatus(props.incidentDetails[0].status);
      setPreviewImage(
        props.incidentDetails[0].imagepath
          ? props.incidentDetails[0].imagepath
          : '../assets/image/dummy.png'
      );
    }
  }, [props.incidentDetails, props.incidentStatus]);

  useEffect(() => {
    const inputElement = document.getElementById('phoneNumberFormat');
    if (inputElement) {
      inputElement.addEventListener('keydown', Util.enforceFormat);
      inputElement.addEventListener('keyup', Util.formatToPhone);
    }
  }, [contactphone]);

  const handleChangeImage = event => {
    if (imagepath) {
      NotificationManager.error(
        'Error Message',
        'Firstly, you have to delete old Attachment to Add New Attachment'
      );
    } else {
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
      setImagePath(event.target.files[0]);
    }
  };

  // download Document //
  const downloadFile = items => {
    if (items && items.name !== undefined) {
    }
    const fileUrl = items;
    new JsFileDownloader({
      url: fileUrl,
    });
  };

  const handleDeleteImg = (id, docFile) => {
    if (docFile && docFile.name) {
      setPreviewImage('../assets/image/dummy.png');
      setImagePath('');
      NotificationManager.error('Success Message', 'Attachment deleted');
    } else if (docFile) {
      const newFileName = Util.getFileNameFromUrl(docFile);
      if (!newFileName || newFileName === 'undefined') {
        NotificationManager.error('Error Message', 'There is no Attachment to delete');
        return;
      }
      S3Client.deleteFile(newFileName)
        .then(data => {
          if (data.message === 'File Deleted') {
            props.getIncidentById({ id: id, delete: 'doc' });
            setPreviewImage('../assets/image/dummy.png');
            setImagePath('');
            NotificationManager.error('Success Message', 'Attachment deleted');
          } else {
            NotificationManager.error('Error Message', 'Oops!! Somwthing went wrong');
          }
        })
        .catch(err => {
          NotificationManager.error('Error Message', `Error while deleting image. ${err}`);
        });
    } else {
      NotificationManager.error('Error Message', 'There is no Attachment to delete');
    }
  };

  const handleSubmit = () => {
    let formdata = {
      id: incidentid,
      featurename: featurename,
      title: title,
      criticality: criticality,
      description: description,
      referenceurl: referenceurl,
      imagepath: null,
      contactphone: contactphone,
      timetocontact: timetocontact,
      userid: userDetails.id,
      userrole: userrole,
      status: status,
    };

    let valid = validate();
    if (valid) {
      if (imagepath && imagepath.name) {
        const newFileName =
          generate_random_string(4) + imagepath.name.split('.').slice(0, -1).join('.').trim();
        S3Client.uploadFile(imagepath, newFileName)
          .then(data => {
            formdata.imagepath = data.location;
            props.addIncident(formdata);
          })
          .catch(error => {
            NotificationManager.error('Error Message', `Error while uploading image!`);
          });
      } else {
        props.addIncident(formdata);
      }
    }
  };

  const validate = () => {
    const errors = [];
    if (featurename.length === 0) {
      errors.push('Featue Name cannot be empty.');
    }
    if (title.length === 0) {
      errors.push('Title cannot be empty.');
    }
    if (criticality.length === 0) {
      errors.push('Criticality cannot be empty.');
    }
    if (referenceurl.length === 0) {
      errors.push('Referenceurl cannot be empty.');
    }
    if (status.length === 0) {
      errors.push('Status cannot be empty.');
    }
    if (errors.length > 0) {
      errors.forEach(element => {
        NotificationManager.error('Error Message', element);
      });
      return false;
    }
    return true;
  };

  let featureslist = sortBy(Object.keys(Util.featuresList()));
  let criticalityList = sortBy(Object.keys(Util.criticalityList()));
  let timetocontactList = sortBy(Object.keys(Util.timetocontactList()));
  let incidentStatusList = sortBy(Object.keys(Util.incidentStatusList()));

  const handleDelete = () => {
    const data = {
      id: incidentid,
      userid: userDetails.id,
      userrole: userDetails.role,
    };
    props.deleteIncident(data);
  };

  return (
    <div className="container-fluid house">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Incident Details</span>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-3">
              <div className="form-group">
                <label htmlFor="featurename" className="req">
                  Feature
                </label>
                <select
                  className="form-control"
                  value={featurename}
                  onChange={e => setFeatureName(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {featureslist.map(feature => {
                    return <option value={feature}>{feature}</option>;
                  })}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="title" className="req">
                  Title
                </label>
                <input
                  type="text"
                  placeholder="Title"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  type="text"
                  rows="4"
                  placeholder="Description"
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label htmlFor="contactphone">Contact Phone</label>
                <input
                  id="phoneNumberFormat"
                  type="phone"
                  maxLength="12"
                  placeholder="Contact Phone"
                  value={contactphone}
                  onChange={e => setContactPhone(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label htmlFor="timetocontact">Contact Time</label>

                <select
                  className="form-control"
                  value={timetocontact}
                  onChange={e => setTimeToContact(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {timetocontactList.map(tc => {
                    return <option value={tc}>{tc}</option>;
                  })}
                </select>
              </div>
            </div>
            <div className="col-sm-3 fluid-div">
              <div className="form-group">
                <label htmlFor="criticality" className="req">
                  Criticality
                </label>
                <select
                  className="form-control"
                  value={criticality}
                  onChange={e => setCriticality(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {criticalityList.map(criticality => {
                    return <option value={criticality}>{criticality}</option>;
                  })}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="status" className="req">
                  Status
                </label>
                <select
                  className="form-control"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <option value="" disabled>
                    Select
                  </option>
                  {incidentStatusList.map(istatus => {
                    return <option value={istatus}>{istatus}</option>;
                  })}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="referenceurl" className="req">
                  Reference Url
                </label>
                <input
                  type="text"
                  placeholder="Referenceurl"
                  value={referenceurl}
                  onChange={e => setReferenceUrl(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group imageUpload">
                <div className="input-group">
                  <span className="input-group-btn">
                    <span className="btn btn-default btn-file">
                      Browse… <input type="file" id="imgInp" />
                    </span>
                  </span>
                  <input
                    type="file"
                    onChange={event => handleChangeImage(event)}
                    className="form-control fileUpload"
                    readOnly
                  />
                </div>
                <div className="imageArea">
                  <img
                    src={previewImage}
                    alt="Upload House Image"
                    className="img-rounded"
                    id="img-upload"
                  />
                </div>
                <div className="dflex">
                  <div onClick={() => downloadFile(imagepath)}>
                    <i
                      className="glyphicon glyphicon-download-alt primary  btn-lg blueIcon"
                      value={imagepath}
                    ></i>
                  </div>
                  <div onClick={() => handleDeleteImg(incidentid, imagepath)}>
                    <i
                      className="glyphicon glyphicon-trash primary  btn-lg blueIcon"
                      value={imagepath}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-md-3">
          <Link
            to={{
              pathname: `/incident-list`,
            }}
            className="btn btn-secondary btn-sm"
            role="button"
          >
            <span className="glyphicon glyphicon-backward"> </span> Back
          </Link>
          {incidentid && userrole === '1' && (
            <button className="btn btn-secondary btn-sm" onClick={() => setIsopen(true)}>
              <span className="glyphicon glyphicon-trash"> </span> Delete Entry
            </button>
          )}
           {isOpen === true && <ConfirmModal title="Confirm`" content="Are you sure you want to delete this incident ?" confirmModal={handleDelete} closeModal={setIsopen}></ConfirmModal>}
        </div>
        <div className="col-sm-6" align="center">
          <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
            Save
          </button>
        </div>
        <div className="col-sm-4"></div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  incidentDetails: state.Incident.incidentDetails.data,
  incidentStatus: state.Incident.status,
  isLoading: state.Spinner.isIncidentLoading,
});

const mapDispatchToProps = {
  addIncident,
  deleteIncident,
  getIncidentById,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetails);
