import React from 'react';

const ConfirmModal = props => {
  return (
    <div
      className="modal"
      id="confrimeModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      den="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <span className="modal-title" id="exampleModalLabel">
              {props.title}
            </span>
            <button type="button" className="close" onClick={props.closeModal}>
              x
            </button>
          </div>
          <div className="modal-body">
            {props.content}
          </div>
          <div className="modal-footer">
          <button type="button" className="btn btn-secondary btn-sm" onClick={props.closeModal}>
              No
            </button>
            <button type="button" className="btn btn-primary btn-sm" onClick={props.confirmModal}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
