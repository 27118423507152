import { Account } from '../../api/api';
import {
  GET_ACCOUNT,
  UPDATE_ACCOUNT,
  CHANGE_PASSWORD,
  ACCOUNT_STATUS,
  ACCOUNT_SPINNER,
} from '../actionTypes';
import { NotificationManager } from 'react-notifications';
import { ShowSpinner, HideSpinner } from '../commondispatch';

export const updateAccount = data => {
  return async dispatch => {
    ShowSpinner(dispatch, ACCOUNT_SPINNER);
    await Account.updateAccount(data)
      .then(res => {
        if (res.status === 200) {
          var data = {
            type: UPDATE_ACCOUNT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, ACCOUNT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, ACCOUNT_SPINNER);
        throw error;
      });
  };
};

export const changePassword = data => {
  return async dispatch => {
    ShowSpinner(dispatch, ACCOUNT_SPINNER);
    await Account.changePassword(data)
      .then(res => {
        if (res && res.status == 200) {
          var data = {
            type: CHANGE_PASSWORD,
            payload: res,
          };
          dispatch(data);
          var status = {
            type: ACCOUNT_STATUS,
            payload: res.status,
          };
          dispatch(status);
          NotificationManager.success('Success Message', 'Password updated successfully.');
        } else {
          NotificationManager.error('Error message', res.statusText);
        }
        HideSpinner(dispatch, ACCOUNT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, ACCOUNT_SPINNER);
        NotificationManager.error('Error Message', 'Oops something went wrong. Try later.');
        throw error;
      });
  };
};

export const getAccount = data => {
  return async dispatch => {
    ShowSpinner(dispatch, ACCOUNT_SPINNER);
    await Account.getAccount(data)
      .then(res => {
        if ((res && res.status === 200) || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_ACCOUNT,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, ACCOUNT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, ACCOUNT_SPINNER);
        throw error;
      });
  };
};
