import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import '../../style/House.css';
import {
  addLoan,
  getSingleLoan,
  addLoanTransaction,
  getLoanTransaction,
  deleteLoanTransaction,
} from '../../store/Actions/Loan';
import { Util } from '../../Datamanipulation/Util';
import Tab from '../../Reusable/Tab';
import Table from '../../Reusable/Table';
import { NotificationManager } from 'react-notifications';
import NumberFormat from 'react-number-format';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const LoanTransaction = props => {
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';
  let loggedinUser = Util.getLoggedinUser();
  const [id, setId] = useState('');
  const [loanamount, setLoanamount] = useState('');
  const [rateofinterest, setRateofinterest] = useState('');
  const [loanterm, setLoanterm] = useState('');
  const [loannumber, setLoannumber] = useState('');
  const [startDate, setStartDate] = useState(Util.getCurrentDate('-'));
  const [endDate, setEndDate] = useState(Util.getCurrentDate('-'));
  const [tableData, setTableData] = useState([]);
  const [loanStatus, setLoanStatus] = useState(true);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [loantype, setLoantype] = useState('');
  const [enteredBy, setEnteredBy] = useState(loggedinUser['name']);
  const [loanclosuredate, setLoanClousreDate] = useState('');

  useEffect(() => {
    let tableData = [];
    if (props.loanTransaction && props.loanTransaction.length > 0) {
      for (let i = 0; i < props.loanTransaction.length; i++) {
        let data = {};
        data.month = i + 1;
        data.loanamount = props.loanTransaction[i].beginingamount;
        data.payment = props.loanTransaction[i].totalpayment;
        data.interest = props.loanTransaction[i].interest;
        data.principal = props.loanTransaction[i].principal;
        data.extra = props.loanTransaction[i].extrapayment;
        data.endingloan = props.loanTransaction[i].endingbalance;
        data.scheduledpayment = props.loanTransaction[i].scheduledpayment;
        data.cumulativeinterest = props.loanTransaction[i].cumulativeinterest;
        data.paymentdate = Util.dateFormat(props.loanTransaction[i].paymentdate);
        data.loan_id = props.loanDetails[0].loan_id;
        tableData.push(data);
      }
      setTableData(tableData);
    }
  }, [props.loanTransaction, props.loanDetails]);

  useEffect(() => {
    if (props.loanDetails && props.loanDetails.length > 0) {
      setLoanamount(props.loanDetails[0].loanamount);
      setRateofinterest(props.loanDetails[0].rateofinterest);
      setLoanterm(props.loanDetails[0].loanterm);
      setLoannumber(props.loanDetails[0].loannumber);
      setStartDate(props.loanDetails[0].loanbegindate);
      setLoanClousreDate(props.loanDetails[0].loanclosuredate);
      setEndDate(Util.getCurrentDate('-'));
      setLoantype(props.loanDetails[0].loantype);
      setId(props.loanDetails[0].id);
    }
  }, [props.loanDetails]);

  const handleSubmit = () => {
    let clousre = new Date(loanclosuredate);
    let current = new Date(Util.getCurrentDate('-'));

    current = Math.floor(current.getTime() / 86400000);
    clousre = Math.floor(clousre.getTime() / 86400000);

    if (current > clousre) {
      NotificationManager.error(
        'Error Message',
        'No Transactions are allowed to post after the loan closure date.'
      );
      return;
    }
    props.addLoanTransaction(tableData);
    setButtonStatus(true);
  };

  const validate = () => {
    if (startDate === '') {
      NotificationManager.error('Error Message', 'Start Date cannot be empty.');
      return false;
    } else if (endDate === '') {
      NotificationManager.error('Error Message', 'End Date cannot be empty.');
      return false;
    }

    let start = new Date(startDate);
    let end = new Date(endDate);

    end = Math.floor(end.getTime() / 86400000);
    start = Math.floor(start.getTime() / 86400000);

    if (start > end) {
      NotificationManager.error('Error Message', 'End Date must be greater than Start Date.');
      return false;
    }
    return true;
  };
  const showTableView = () => {
    let valid = validate();
    if (valid) {
      let loanData = props.loanDetails;
      /**Calculating Amortization */
      let tableData = [];

      if (loanData) {
        var months = Util.monthDiff(startDate, endDate);
        var payment = '';
        let roi = parseFloat(
          loanData[0].renewal_intrest_rate ? loanData[0].renewal_intrest_rate : rateofinterest
        );
        var mroi = roi / (12 * 100);
        let loa = parseInt(Util.removeCommas(loanData[0].loanamount));
        let term = parseInt(loanData[0].loanterm * 12);
        for (let i = 0; i < months; i++) {
          let data = {};
          let loanamount;
          let interest = '';
          let principal = '';
          let extra = '';
          let endingloan = '';
          let scheduledpayment = '';
          let cumulativeinterest = '';
          let paymentdate = '';
          if (i == 0) {
            const firtInterest = loa * mroi;
            payment = loa / ((Math.pow(1 + mroi, term) - 1) / (mroi * Math.pow(1 + mroi, term)));
            loanamount = loa;
            payment = payment;
            interest = firtInterest;
            principal = payment - interest;
            extra = '';
            endingloan = loanamount - principal;

            scheduledpayment = '';
            cumulativeinterest = firtInterest;
            paymentdate = new Date(startDate);
          } else {
            const previousData = Util.removeCommas(tableData[i - 1].endingloan);
            const previousCumulativeinterest = Util.removeCommas(
              tableData[i - 1].cumulativeinterest
            );
            const currentInterest = previousData * mroi;
            loanamount = Util.removeCommas(previousData);
            payment = Util.removeCommas(payment);
            interest = currentInterest;
            principal = payment - interest;
            extra = '';
            endingloan = previousData - principal;
            scheduledpayment = '';
            cumulativeinterest = currentInterest + previousCumulativeinterest;
            let date = new Date(startDate);
            paymentdate = new Date(date.setMonth(date.getMonth() + i));
          }
          data.month = i + 1;
          data.loanamount = Util.addCommasList(parseFloat(loanamount).toFixed(2));
          data.payment = Util.addCommasList(parseFloat(payment).toFixed(2));
          data.interest = Util.addCommasList(parseFloat(interest).toFixed(2));
          data.principal = Util.addCommasList(parseFloat(principal).toFixed(2));
          data.extra = mroi;
          data.endingloan = Util.addCommasList(parseFloat(endingloan).toFixed(2));
          data.loan_id = props.loanDetails[0].id;
          data.scheduledpayment = scheduledpayment;
          data.cumulativeinterest = cumulativeinterest;
          data.entered_by = enteredBy;
          let day = paymentdate.getDay();
          let month = paymentdate.getMonth();
          let year = paymentdate.getFullYear();
          let hour = paymentdate.getHours();
          let minutes = paymentdate.getMinutes();
          data.paymentdate = Util.dateFormat(paymentdate);
          //month+"/"+day+"/"+year + " "+ hour +":"+minutes;

          tableData.push(data);
        }
        setLoanStatus(true);
        setButtonStatus(false);
      }

      if (tableData.length == 0) {
        let data = {};
        data.loan_id = props.loanDetails && props.loanDetails[0].id;
        data.delete = true;
        tableData.push(data);
      }

      setTableData(tableData);
    }
  };

  let tabs = [
    { pathname: `/loan-lender/${house_id}`, label: 'Lender Details' },
    { pathname: `/loan-details/${house_id}`, label: 'Loan Details' },
  ];

  if (loantype === 'Mortgage') {
    tabs.push({
      pathname: `/loan-additionals/${house_id}`,
      label: 'Escrow & Property Tax',
    });
  }

  tabs.push({ pathname: `/loan-transaction/${house_id}`, label: 'Loan Transactions' });

  var columns = [
    { name: 'PMT NO', selector: 'month', sortable: true },
    { name: 'Payment Date', selector: 'paymentdate', sortable: true },
    { name: 'Begining Balance', selector: 'loanamount', sortable: true },
    { name: 'Scheduled Payment', selector: 'scheduledpayment', sortable: true },
    { name: 'Extra Payment', selector: 'extra', sortable: true },
    { name: 'Total Payment', selector: 'payment', sortable: true },
    { name: 'Principal', selector: 'principal', sortable: true },
    { name: 'Interest', selector: 'interest', sortable: true },
    { name: 'Ending Balance', selector: 'endingloan', sortable: true },
    { name: 'Cumulative Interest', selector: 'cumulativeinterest', sortable: true },
  ];

  const handleDelete = () => {
    props.deleteLoanTransaction({
      loan_id: props.loanDetails[0].id,
    });
    setTableData([]);
  };

  return (
    <div className="container-fluid house">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Transaction Details</span>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <Tab loanPage="Loan Transactions" tabs={tabs} id={id} house_id={house_id} />
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 house-form ">
              <span className="" id="text">
                Let's generate all the transaction for this loan you have paid till date.
              </span>
              <br></br>
              <br></br>
              <div className="row ">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="Account no">Account no</label>
                    <input
                      type="text"
                      placeholder="Account no"
                      value={loannumber}
                      onChange={e => setLoannumber(e.target.value)}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="Loan Amount">Loan Amount</label>
                    <NumberFormat
                      placeholder="Loan Amount"
                      thousandsGroupStyle="thousand"
                      className="form-control alignRight"
                      value={loanamount}
                      decimalSeparator="."
                      type="text"
                      thousandSeparator={true}
                      allowNegative={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      allowEmptyFormatting={true}
                      allowLeadingZeros={false}
                      onChange={e => setLoanamount(e.target.value)}
                      isNumericString={true}
                      readOnly
                    />
                    {/* <input type="text" placeholder="Loan Amount" value={loanamount} onChange={e=> setLoanamount(e.target.value)} className="form-control" readOnly/> */}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="Rate of Interest(%)">Rate of Interest(%)</label>
                    <input
                      type="text"
                      placeholder="Rate of Interest(%)"
                      value={rateofinterest}
                      onChange={e => setRateofinterest(e.target.value)}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Start Date">Start Date</label>
                    <input
                      type="date"
                      id="startDate"
                      placeholder="Start Date"
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Start Date">End Date</label>
                    <input
                      type="date"
                      id="endDate"
                      placeholder="Start Date"
                      value={endDate}
                      onChange={e => setEndDate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4" align="center" style={{ marginTop: '15px' }}>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm addNewItem "
                    onClick={showTableView}
                  >
                    Generate Transaction
                  </button>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          <div className="inner-form mt-25">
            <Table
              header={''}
              url={''}
              columns={columns}
              getSingleData={props.getSingleLoan}
              tableId="amortization"
              data={tableData}
              house_id={house_id}
            />
          </div>
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-md-4"></div>
        <div className="col-md-4" align="center">
          <button
            className="btn btn-primary btn-sm"
            onClick={handleSubmit}
            style={buttonStatus ? { display: 'none' } : { display: 'block' }}
          >
            Confirm And Save
          </button>
        </div>

        <div className="col-md-4  right-button">
          {/* <button className="btn btn-primary btn-sm"  onClick={handleDelete}
            style={buttonStatus ? { display: 'block' } : { display: 'none' }}>
            Delete
          </button> */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loanDetails: state.Loan.loanDetails.data,
  loanTransaction: state.Loan.loanTransaction.data,
  isLoading: state.Spinner.isLoanLoading,
});

const mapDispatchToProps = {
  addLoan,
  getSingleLoan,
  addLoanTransaction,
  getLoanTransaction,
  deleteLoanTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanTransaction);
