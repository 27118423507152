import React, { useState, useEffect } from 'react';
import '../../style/Contact.css';
import { addContact, deleteContact } from '../../store/Actions/contact';
import { connect } from 'react-redux';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import { Util } from '../../Datamanipulation/Util';

const Report = props => {
  const [companyname, setCompanyname] = useState('');
  const [fromdate, setFromdate] = useState(Util.getCurrentDate('-'));
  const [todate, setTodate] = useState('');

  useEffect(() => {}, [props.contactDetails]);

  const handleSubmit = () => {};

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Report Details</span>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4 ">
                  <div className="form-group">
                    <label htmlFor="First Name" className="">
                      Group/Company Name
                    </label>
                    <input
                      type="text"
                      placeholder="Group/Company Name"
                      value={companyname}
                      onChange={e => setCompanyname(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className="form-group">
                    <label htmlFor="From Date" className="">
                      From Date
                    </label>
                    <input
                      type="date"
                      placeholder="From Date"
                      value={fromdate}
                      onChange={e => setFromdate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className="form-group">
                    <label htmlFor="To Date" className="">
                      To Date
                    </label>
                    <input
                      type="text"
                      placeholder="To Date"
                      value={todate}
                      onChange={e => setTodate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12" align="center">
          <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
            Share It
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  contactDetails: state.Contact.contactDetails.data,
  // contacts: state.Contact.contacts.data,
  houseDetails: state.House.houseDetail.data,
  isLoading: state.Spinner.isContactLoading,
});

const mapDispatchToProps = {
  addContact,
  deleteContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
