import React from 'react';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

class WarrantyTable extends React.Component {
  constructor(props) {
    super(props);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  handleRowClick(id) {
    if (this.props.tableId !== 'amortization') {
      var data = { id: id };
      this.props.getSingleData(data);
    }
  }

  render() {
    const tableData = {
      data: this.props.data,
      columns: this.props.columns,
    };
    const paginationComponentOptions = {
      noRowsPerPage: true,
    };
    const customStyles = {
      headCells: {
        style: {
          color: '#878391',
          fontSize: '13px',
          fontWeight: 600,
        },
      },
      headRow: {
        style: {
          borderBottomWidth: '0px',
          borderBottomColor: 'transparent',
        },
      },
      rows: {
        style: {
          minHeight: '40px',
          color: '#383345',
          backgroundColor: '#F1F5FA',
          '&:not(:last-of-type)': {
            borderBottomWidth: '0px',
            borderBottomColor: 'transparent',
          },
        },
      },
      pagination: {
        style: {
          borderTopWidth: '0px',
          borderTopColor: 'transparent',
        },
      },
    };
    return (
      <DataTableExtensions {...tableData} export={true} print={false} filterPlaceholder={'Search'}>
        <DataTable
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          striped={true}
          filterHidden={true}
          customStyles={customStyles}
          paginationComponentOptions={paginationComponentOptions}
          onRowClicked={row => this.handleRowClick(row.id)}
        />
      </DataTableExtensions>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyTable);
