import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addGallery, getGallery, deleteGallery } from '../../store/Actions/Gallery';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import Slider from 'react-slick';
import S3 from 'aws-s3';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import config from '../../config';
import { NotificationManager } from 'react-notifications';

const Galleries = props => {
  // aws-s3 uploader//
  const s3Config = config.REACT_APP_S3_CLIENT_CONFIG;
  const bucketName =
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user')).bucket_folder_name;
  s3Config.dirName = `${bucketName}/Gallery`;

  const S3Client = new S3(s3Config);
  const generate_random_string = string_length => {
    let random_string = '';
    let random_ascii;
    let ascii_low = 65;
    let ascii_high = 90;
    for (let i = 0; i < string_length; i++) {
      random_ascii = Math.floor(Math.random() * (ascii_high - ascii_low) + ascii_low);
      random_string += String.fromCharCode(random_ascii);
    }
    return random_string;
  };
  let houseId = props.location.state.house_id ? props.location.state.house_id : '';
  const [id, setId] = useState('');
  const [attachment, setAttachment] = useState('');
  const [album_name, setAlbum_name] = useState('Home');
  const [download, setDownload] = useState('');
  const [docName, setDocName] = useState('');
  const [house_id, setHouse_id] = useState(houseId);
  const [active, setActive] = useState('All');

  useEffect(() => {
    if (attachment) {
      const newFileName =
        generate_random_string(4) + attachment.name.split('.').slice(0, -1).join('.');

      S3Client.uploadFile(attachment, newFileName)
        .then(data => {
          var form = new FormData();
          form.append('id', id);
          form.append('house_id', house_id);
          form.append('attachment', data.location);
          form.append('album_name', album_name);
          props.addGallery(form);
          setAttachment('');
          setAlbum_name(album_name);
        })
        .catch(err => {
          NotificationManager.error('Error Message', `Error while uploading image. ${err}`);
        });
    }
  }, [attachment]);

  useEffect(() => {
    if (!props.galleries && active === 'All') {
      props.getGallery({ album_name: null, house_id: house_id });
    }
  }, [props.galleries]);

  const handleDocumentUpload = event => {
    setAttachment(event.target.files[0]);
  };

  const handleDelete = (id, docFile) => {
    if (!docFile) {
      NotificationManager.error('Error Message', 'There is no Attachment to delete');
      return;
    }
    const newFileName = Util.getFileNameFromUrl(docFile);
    if (!newFileName || newFileName === 'undefined') {
      NotificationManager.error('Error Message', 'There is no Attachment to delete');
      return;
    }
    S3Client.deleteFile(newFileName)
      .then(data => {
        if (data.fileName !== undefined) {
          props.deleteGallery({ id: id, house_id: house_id, album_name: album_name });
        } else {
          props.deleteGallery({ id: id, house_id: house_id, album_name: album_name });
        }
      })
      .catch(err => {
        NotificationManager.error('Error Message', `Error while deleting image. ${err}`);
      });
  };

  const handleDocType = ablumName => {
    setActive(ablumName);
    if (ablumName === 'All') {
      ablumName = null;
    } else {
      setAlbum_name(ablumName);
    }
    props.getGallery({ album_name: ablumName, house_id: 84 });
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      props.galleries && props.galleries.length > 3 ? 3 : props.galleries && props.galleries.length,
    slidesToScroll: 3,
  };

  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Gallery</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Galleries)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row top-bar">
            <div className="col-md-12 text-align-left">
              <span
                className={active === 'All' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('All')}
              >
                All
              </span>
              <span
                className={active === 'Home' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Home')}
              >
                Home
              </span>
              <span
                className={active === 'Personal' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Personal')}
              >
                Personal
              </span>
              <span
                className={active === 'Other' ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('Other')}
              >
                Other
              </span>
            </div>
          </div>
          {active !== 'All' && (
            <div className="row" style={{ marginTop: '25px' }}>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="attachment">Select Image</label>
                  <label htmlFor="file" className="fileContainer">
                    <div className="attachfile" align="center">
                      <i>Click here to attach Image</i>
                      <p>{docName ? docName : ''}</p>
                    </div>
                    <input
                      type="file"
                      style={{ height: '0px', visibility: 'hidden' }}
                      id="file"
                      onChange={event => handleDocumentUpload(event)}
                      className="form-control"
                      accept="audio/*,video/*,image/*"
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* *Galary Code */}
        <div className="inner-form galary">
          <div className="row">
            {props.galleries && props.galleries.length > 0 && (
              <Slider {...settings}>
                {props.galleries.map((image, index) => {
                  return (
                    <div className="col-md-3" key={index}>
                      <div className="imageArea">
                        <img src={image.attachment} id="img-upload" />
                      </div>
                      <div className="galaryBody">
                        <div className="date">{image.uploaded_at}</div>
                        <div className="deleteImage">
                          <span
                            className="glyphicon glyphicon-trash"
                            onClick={e => handleDelete(image.id, image.attachment)}
                          ></span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  galleries: state.Gallery.galleries.data,
  isLoading: state.Spinner.isGalleryLoading,
});

const mapDispatchToProps = {
  addGallery,
  getGallery,
  deleteGallery,
};

export default connect(mapStateToProps, mapDispatchToProps)(Galleries);
