import React, { useState, useEffect } from 'react';
import '../../style/Contact.css';
import {
  addTransaction,
  getSingleTransaction,
  deleteTransaction,
} from '../../store/Actions/Transaction';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { Util } from '../../Datamanipulation/Util';
import { getContactForTransaction, getContact } from '../../store/Actions/contact';
import ContactModal from '../Contacts/Contact-Modal';
import NumberFormat from 'react-number-format';
import S3 from 'aws-s3';
import JsFileDownloader from 'js-file-downloader';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import config from '../../config';
import ConfirmModal from '../../Reusable/Confirm-Modal';

const Transaction = props => {
  // aws-s3 uploader//
  const s3Config = config.REACT_APP_S3_CLIENT_CONFIG;
  const bucketName =
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user')).bucket_folder_name;
  s3Config.dirName = `${bucketName}/Transactions`;

  const S3Client = new S3(s3Config);
  const generate_random_string = string_length => {
    let random_string = '';
    let random_ascii;
    let ascii_low = 65;
    let ascii_high = 90;
    for (let i = 0; i < string_length; i++) {
      random_ascii = Math.floor(Math.random() * (ascii_high - ascii_low) + ascii_low);
      random_string += String.fromCharCode(random_ascii);
    }
    return random_string;
  };

  let houseId = props.location.state.house_id ? props.location.state.house_id : '';
  let loggedinUser = Util.getLoggedinUser();

  const [accountName, setAccountName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [transactionType, setTransactionType] = useState('');
  const [transactiondate, setDate] = useState(Util.getCurrentDate('-'));
  const [transactionAmount, setTransactionAmount] = useState('');
  const [enteredBy, setEnteredBy] = useState(loggedinUser['name']);
  const [comments, setComments] = useState('');
  const [addToHomeCost, setAddToHomeCost] = useState(0);
  const [docName, setDocName] = useState('');
  const [document, setDocument] = useState('');
  const [download, setDownload] = useState('');
  const [id, setId] = useState('');
  const [house_id, setHouse_id] = useState(houseId);
  const [addToWarrantyCost, setAddToWarrantyCost] = useState(0);
  const [showGroup, setShowGroup] = useState(false);
  const [product_name, setProduct_name] = useState('');
  const [warranty_id, setWarranty_id] = useState('');
  const [contactList, setContactList] = useState([]);

  /**Escrow Details */
  const [principal, setPrincipal] = useState('');
  const [interest, setInterest] = useState('');
  const [extraprincipal, setExtraprincipal] = useState('');
  const [escrow, setEscrow] = useState('');
  const [loanbalance, setLoanbalance] = useState('');
  const [escrowbalance, setEscrowbalance] = useState('');
  const [escrowStatus, setEscrowStatus] = useState('No');
  const [loan_id, setLoanId] = useState(0);
  const [contactData, setContactData] = useState();
  const [monthlyEstmates, setMonthlyEstamtes] = useState();
  const [paymentTransactionData, setPaymentTransactionData] = useState();
  const [groupName, setGroupName] = useState();
  const [isOpen, setIsopen] = useState(false);

  const togglePopup = () => {
    setShowGroup(!showGroup);
    let data = {
      house_id: house_id,
    };
    props.getContact(data);
  };

  useEffect(() => {
    if (props.transactions && props.transactions.length > 0) {
      const payments = props.transactions.filter(f => f.type === 'Payment');
      setPaymentTransactionData(payments);
    }
  }, [props.transactions]);

  useEffect(() => {
    if (props.transactionDetails && props.transactionDetails.length > 0) {
      const account_name = props.transactionDetails[0].account_name;
      handleContatData(account_name);
      setAccountName(account_name);
      setContactPerson(props.transactionDetails[0].contact_person);
      setTransactionType(props.transactionDetails[0].type);
      setDate(props.transactionDetails[0].date);
      setTransactionAmount(props.transactionDetails[0].amount);
      setEnteredBy(props.transactionDetails[0].entered_by);
      setComments(props.transactionDetails[0].comments);
      setAddToHomeCost(props.transactionDetails[0].add_to_home_cost);
      setHouse_id(props.transactionDetails[0].house_id);
      setId(props.transactionDetails[0].id);
      setProduct_name(
        props.transactionDetails[0].product_name ? props.transactionDetails[0].product_name : ''
      );
      setWarranty_id(
        props.transactionDetails[0].warranty_id ? props.transactionDetails[0].warranty_id : ''
      );
      setAddToWarrantyCost(props.transactionDetails[0].add_to_warranty);
      setDocName(
        props.transactionDetails[0].receipt &&
          props.transactionDetails[0].receipt.includes('/') &&
          props.transactionDetails[0].receipt.split('/')[4].slice(4)
      );
      setDownload(props.transactionDetails[0].receipt);
      setDocument(props.transactionDetails[0].receipt);
      /**Escrow */
      setPrincipal(
        props.transactionDetails[0].principal ? props.transactionDetails[0].principal : 0
      );
      setInterest(props.transactionDetails[0].interest ? props.transactionDetails[0].interest : 0);
      setExtraprincipal(
        props.transactionDetails[0].extraprincipal ? props.transactionDetails[0].extraprincipal : 0
      );
      setEscrow(props.transactionDetails[0].escrow ? props.transactionDetails[0].escrow : 0);
      setLoanbalance(
        props.transactionDetails[0].loanbalance ? props.transactionDetails[0].loanbalance : 0
      );
      setEscrowbalance(
        props.transactionDetails[0].escrowbalance ? props.transactionDetails[0].escrowbalance : 0
      );

      setEscrowStatus('No');
      if (props.loan) {
        for (let i = 0; i < props.loans.length; i++) {
          if (
            props.loans[i]['lname'] == props.transactionDetails[0].account_name &&
            props.loans[i]['escrow'] == 'Yes'
          ) {
            setEscrowStatus('Yes');
          }
        }
      }
      handleAccountNameChange(account_name);
    }
    if (props.houseDetails && props.houseDetails.house.length > 0) {
      setHouse_id(props.houseDetails.house[0].id);
    }
    let data = {
      house_id: house_id,
    };
    props.getContactForTransaction(data);
  }, [props.transactionDetails]);

  useEffect(() => {
    if (props.contactList && props.contactList.length > 0) {
      const contactloanList = [];
      const loopcount = props.contactList.length > 10 ? 10 : props.contactList.length;
      for (let i = 0; i < loopcount; i++) {
        const contactid = props.contactList[i].id;
        const contactloans = props.loans.filter(f => {
          return parseInt(f.lname) === contactid;
        });
        if (contactloans && contactloans.length > 0) {
          for (let j = 0; j < contactloans.length; j++) {
            const companyname = props.contactList[i].companyname;
            const loannumber = contactloans[j].loannumber;
            contactloanList.push({
              id: `${contactid}-${companyname}-${loannumber}`,
              companyname: companyname,
              contactperson: props.contactList[i].contactperson,
              loan: contactloans[j],
            });
          }
          const orderedContactList = contactloanList.sort((a, b) => {
            const ac = a['companyname'].replace(/\s/g, '');
            const bc = b['companyname'].replace(/\s/g, '');
            return ac.localeCompare(bc);
          });
          setContactList(orderedContactList);
        }
      }
    }
  }, [props.contactList, props.loans]);

  const handleTransactionAmount = amt => {
    setTransactionAmount(amt);
    const transamt = parseFloat(Util.removeCommas(amt));
    const monthlyInterest = parseFloat(monthlyEstmates['interest']);
    const monthlyPrincial = parseFloat(monthlyEstmates['principal']);
    const balanceloan = parseFloat(monthlyEstmates['balanceloan']);
    let calInterest = 0;
    let calPrincipal = 0;
    let calExtraPrincipal = 0;
    if (transamt <= monthlyInterest) {
      calInterest = transamt;
      calPrincipal = 0;
      calExtraPrincipal = 0;
      setExtraprincipal(calExtraPrincipal);
      setPrincipal(calPrincipal);
      setInterest(calInterest);
    } else if (transamt > monthlyInterest) {
      const balanceamt = transamt - monthlyInterest;
      calInterest = monthlyInterest;
      setInterest(calInterest);
      if (balanceamt <= monthlyPrincial) {
        calPrincipal = balanceamt;
        calExtraPrincipal = 0;
        setPrincipal(calPrincipal);
        setExtraprincipal(calExtraPrincipal);
      } else if (balanceamt > monthlyPrincial) {
        calPrincipal = monthlyPrincial;
        calExtraPrincipal = parseFloat(balanceamt - monthlyPrincial);
        setPrincipal(calPrincipal);
        setExtraprincipal(calExtraPrincipal);
      }
    }
    const balanceamt = balanceloan - (calPrincipal + calExtraPrincipal);
    setLoanbalance(balanceamt);
  };

  const handleContatData = dataId => {
    const myObj = props.contactList.find(obj => obj.id === parseInt(dataId.split('-')[0]));
    if (myObj) {
      setContactPerson(myObj.contactperson);
      setTransactionType(myObj.transaction_type);
      setGroupName(myObj.groupname);
      setAddToHomeCost(myObj.add_to_home_cost);
      setComments(myObj.comment);
    }
    setContactData(myObj);
  };

  const handleSubmit = () => {
    let formdata = {
      account_name: accountName,
      contact_person: contactPerson,
      type: transactionType,
      date: transactiondate,
      amount: transactionAmount,
      entered_by: enteredBy,
      comments: comments,
      add_to_home_cost: addToHomeCost,
      add_to_warranty: addToWarrantyCost,
      debit: '',
      house_id: house_id,
      id: id,
      product_name: product_name,
      principal: principal,
      interest: interest,
      extraprincipal: extraprincipal,
      escrow: escrow,
      loanbalance: loanbalance,
      escrowbalance: escrowbalance,
      escrowStatus: escrowStatus,
    };

    let closeStatus = true;
    let current = new Date(transactiondate);
    if (props.loans) {
      for (let i = 0; i < props.loans.length; i++) {
        // Comparing dates
        let enddate = new Date(props.loans[i].loanclosuredate);
        if (props.loans[i].escrowPayee == contactPerson) {
          enddate = Math.floor(enddate.getTime() / 86400000);
          current = Math.floor(current.getTime() / 86400000);
          if (current > enddate) {
            closeStatus = false;
          }
          break;
        }
      }
    }

    if (closeStatus) {
      let valid = validate();
      if (valid) {
        if (document && document.name) {
          const newFileName =
            generate_random_string(4) + document.name.split('.').slice(0, -1).join('.');
          S3Client.uploadFile(document, newFileName)
            .then(data => {
              var form = new FormData();
              for (const key in formdata) {
                form.append(key, formdata[key]);
              }
              form.append('receipt', data.location);
              props.addTransaction(form);
              props.history.push({
                pathname: `/transaction-list/${house_id}`,
                state: {
                  house_id: house_id,
                },
              });
            })
            .catch(err => {
              NotificationManager.error('Error Message', `Error while uploading image. ${err}`);
            });
        } else {
          var form = new FormData();
          for (const key in formdata) {
            form.append(key, formdata[key]);
          }
          props.addTransaction(form);
          props.history.push({
            pathname: `/transaction-list/${house_id}`,
            state: {
              house_id: house_id,
            },
          });
        }
      }
    } else {
      NotificationManager.error('Error Message', 'Loan already expired.');
    }
  };
  const handleDeleteTransaction = () => {
    let data = {
      id: id,
      house_id: house_id,
    };
    props.deleteTransaction(data);
    props.history.push({
      pathname: `/transaction-list/${house_id}`,
      state: { house_id: house_id },
    });
  };

  const handleAccountNameChange = accountName => {
    if (!accountName) return;
    const data = contactList.find(obj => obj.id === accountName);
    if (!data) return;
    setAccountName(accountName);
    handleContatData(accountName);
    setEscrowStatus('No');
    setLoanId(data.loan['id']);
    if (data.loan['escrow'] == 'Yes') {
      setEscrowStatus('Yes');
    }
    let amountPaid = 0;
    if (paymentTransactionData) {
      const amountPaidObj = paymentTransactionData.filter(f => f.account_name === accountName);
      if (amountPaidObj) {
        let principalPaid = 0;
        let extraPrincipalPaid = 0;
        amountPaidObj.forEach(element => {
          principalPaid += parseFloat(element['principal']);
          extraPrincipalPaid += parseFloat(element['extraprincipal']);
        });
        amountPaid = principalPaid + extraPrincipalPaid;
      }
    }
    const estimate = Util.getEstimatedLoanTansaction(data.loan, amountPaid);
    setMonthlyEstamtes(estimate);
    if (estimate) {
      setPrincipal(estimate.principal ? estimate.principal : 0);
      setInterest(estimate.interest ? estimate.interest : 0);
      setLoanbalance(estimate.balanceloan ? estimate.balanceloan : 0);
    }
  };

  const validate = () => {
    if (addToWarrantyCost === 1 && product_name === '') {
      NotificationManager.error('Error Message', 'Product name cannot be empty.');
      return false;
    }
    return true;
  };
  // upload document //
  const handleDocumentUpload = event => {
    if (document) {
      NotificationManager.error(
        'Error Message',
        'Firstly, you have to delete old Attachment to Add New Attachment'
      );
    } else {
      setDocument(event.target.files[0]);
      setDocName(event.target.files[0]['name']);
    }
  };
  // delete Document //
  const handleDelete = (id, docFile) => {
    if (docFile && docFile.name) {
      setDocName('');
      setDocument('');
      NotificationManager.error('Success Message', 'Attachment deleted');
    } else if (docFile) {
      const newFileName = Util.getFileNameFromUrl(docFile);
      if (!newFileName || newFileName === 'undefined') {
        NotificationManager.error('Error Message', 'There is no Attachment to delete');
        return;
      }
      S3Client.deleteFile(newFileName)
        .then(data => {
          if (data.message === 'File Deleted') {
            props.getSingleTransaction({ id: id, delete: 'doc' });
            setDocName('');
            setDocument('');
            NotificationManager.error('Success Message', 'Attachment deleted');
          } else {
            NotificationManager.error('Error Message', 'Oops!! Somwthing went wrong');
          }
        })
        .catch(err => {
          NotificationManager.error('Error Message', `Error while deleting image. ${err}`);
        });
    } else {
      NotificationManager.error('Error Message', 'There is no Attachment to delete');
    }
  };
  // download Document //
  const downloadFile = items => {
    if (items.name !== undefined) {
    }
    const fileUrl = items;
    new JsFileDownloader({
      url: fileUrl,
    });
  };

  // view Document //
  const handleViewEvent = data => {
    window.open(data, '_blank');
  };

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Transaction</span>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row pb-2">
            <div className="col-md-3"></div>
            <div className="col-md-6 house-form pt-25">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="name">Company Name / Account Name</label>
                    <select
                      className="form-control"
                      value={accountName}
                      onChange={e => handleAccountNameChange(e.target.value)}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {contactList
                        ? contactList.map((data, index) => {
                            return (
                              <option value={data.id} key={'ca' + index}>
                                {data.companyname} - ({data.contactperson}) - (
                                {data.loan.loannumber})
                              </option>
                            );
                          })
                        : ''}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Contact Person" className="">
                      Contact Person
                    </label>
                    <input
                      type="text"
                      placeholder="Contact Person"
                      value={contactPerson}
                      onChange={e => setContactPerson(e.target.value)}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Group Name" className="">
                      Group Name
                    </label>
                    <input
                      type="text"
                      placeholder="Group Name"
                      value={groupName}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Transaction Type">Transaction Type</label>
                    <select
                      className="form-control"
                      value={transactionType}
                      onChange={e => setTransactionType(e.target.value)}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Payment">Payment</option>
                      <option value="Receipt">Receipt</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Date" className="">
                      Date
                    </label>
                    <input
                      type="date"
                      placeholder="Date"
                      value={transactiondate}
                      onChange={e => setDate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Transaction Amount">Transaction Amount</label>
                    <NumberFormat
                      placeholder="Transaction Amount"
                      thousandsGroupStyle="thousand"
                      className="form-control alignRight"
                      value={transactionAmount}
                      decimalSeparator="."
                      type="text"
                      thousandSeparator={true}
                      allowNegative={true}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      allowEmptyFormatting={true}
                      allowLeadingZeros={false}
                      onChange={e => handleTransactionAmount(e.target.value)}
                      isNumericString={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Entered By" className="">
                      Entered By
                    </label>
                    <input
                      type="text"
                      placeholder="Entered By"
                      value={enteredBy}
                      onChange={e => setEnteredBy(e.target.value)}
                      className="form-control"
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/* Escrow Details */}
              {transactionType == 'Payment' ? (
                <div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="Principal">Principal</label>
                        <input
                          type="text"
                          readOnly
                          placeholder="Principal"
                          value={Util.addCommas(principal)}
                          onChange={e => setPrincipal(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="Interest" className="">
                          Interest
                        </label>
                        <input
                          type="text"
                          placeholder="Interest"
                          value={Util.addCommas(interest)}
                          readOnly
                          onChange={e => setInterest(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="Extra Principal" className="">
                          Extra Principal
                        </label>
                        <input
                          type="text"
                          placeholder="Extra Principal"
                          readOnly
                          value={Util.addCommas(extraprincipal)}
                          onChange={e => setExtraprincipal(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="Escrow">Escrow</label>
                        <input
                          type="text"
                          placeholder="Escrow"
                          readOnly
                          value={Util.addCommas(escrow)}
                          onChange={e => setEscrow(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="Loan Balance" className="">
                          Loan Balance
                        </label>
                        <input
                          type="text"
                          placeholder="Loan Balance"
                          readOnly
                          value={Util.addCommas(loanbalance)}
                          onChange={e => setLoanbalance(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="Escrow Balance" className="">
                          Escrow Balance
                        </label>
                        <input
                          type="text"
                          placeholder="Escrow Balance"
                          readOnly
                          value={Util.addCommas(escrowbalance)}
                          onChange={e => setEscrowbalance(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* Escrow Details complete*/}
              <div className="row d_flex">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="attachment">Attachments</label>
                    <label htmlFor="file" className="fileContainer">
                      <div className="attachfile" align="center">
                        <i>Click here to attach documents</i>
                        <p>{docName ? docName : ''}</p>
                      </div>
                      <input
                        type="file"
                        style={{ height: '0px', visibility: 'hidden' }}
                        id="file"
                        onChange={event => handleDocumentUpload(event)}
                        className="form-control"
                      />
                    </label>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="dflex">
                    {document && document !== 'undefined' && !document.name && (
                      <div>
                        <div onClick={() => handleViewEvent(document)}>
                          <i
                            className="glyphicon glyphicon-eye-open primary  btn-lg blueIcon"
                            value={document}
                          ></i>
                        </div>
                        <div onClick={() => downloadFile(document)}>
                          <i
                            className="glyphicon glyphicon-download-alt primary  btn-lg blueIcon"
                            value={document}
                          ></i>
                        </div>
                      </div>
                    )}
                    {document && document !== 'undefined' && (
                      <i
                        className="glyphicon glyphicon-trash primary  btn-lg  blueIcon"
                        value={document}
                        onClick={() => handleDelete(id, document)}
                      ></i>
                    )}
                  </div>
                </div>
              </div>

              <div className="row  d_flex">
                <div className="col-md-8">
                  <div className="form-group">
                    <label htmlFor="comments">Comments</label>
                    <textarea
                      rows="4"
                      placeholder="Comments"
                      value={comments}
                      onChange={e => setComments(e.target.value)}
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="checkbox-inline">
                      <input
                        type="checkbox"
                        name="homecost"
                        value={addToHomeCost}
                        onChange={e => setAddToHomeCost(addToHomeCost == 0 ? 1 : 0)}
                        checked={addToHomeCost === 1 ? 'checked' : false}
                      />
                      Add to Home Cost
                    </label>
                  </div>
                  <div className="form-group">
                    <label className="checkbox-inline">
                      <input
                        type="checkbox"
                        name="warrantycost"
                        value={addToWarrantyCost}
                        onChange={e => setAddToWarrantyCost(addToWarrantyCost == 0 ? 1 : 0)}
                        checked={addToWarrantyCost === 1 ? 'checked' : false}
                      />
                      Add to Warranty
                    </label>
                  </div>
                </div>
              </div>

              {addToWarrantyCost ? (
                <div className="row ">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="product" className="req">
                        Product Name
                      </label>
                      <input
                        type="text"
                        placeholder="Product Name"
                        value={product_name}
                        onChange={e => setProduct_name(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="col-md-3"></div>
          </div>

          {showGroup ? <ContactModal house_id={house_id} toggle={togglePopup} /> : null}
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-md-6  ">
          <Link
            to={{
              pathname: `/transaction-list/${house_id}`,
              state: { house_id: house_id },
            }}
            className="btn btn-secondary btn-sm"
            role="button"
          >
            <span className="glyphicon glyphicon-backward"> </span> Back
          </Link>
          {id && (
            <button className="btn btn-secondary btn-sm" onClick={() => setIsopen(true)}>
              <span className="glyphicon glyphicon-trash"> </span> Delete Entry
            </button>
          )}
           {isOpen === true && <ConfirmModal title="Confirm`" content="Are you sure you want to delete this transcation ?" confirmModal={handleDeleteTransaction} closeModal={setIsopen}></ConfirmModal>}
        </div>
        <div className="col-md-6">
          <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  transactions: state.Transaction.transactions.data,
  transactionDetails: state.Transaction.transactionDetails.data,
  contactList: state.Contact.contacts.data,
  loans: state.Loan.loans.data,
  isLoading: state.Spinner.isTransactionLoading,
});

const mapDispatchToProps = {
  addTransaction,
  getSingleTransaction,
  getContactForTransaction,
  getContact,
  deleteTransaction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
