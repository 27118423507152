import React, { useEffect, useState } from 'react';
import '../../style/Contact.css';
import { Link, useParams } from 'react-router-dom';
import {
  getTransaction,
  getSingleTransaction,
  getTransactionAllData,
  unDeleteTransaction,
  resetTransactionDetails,
} from '../../store/Actions/Transaction';
import { getContact } from '../../store/Actions/contact';
import { connect } from 'react-redux';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const TransactionList = props => {
  const house_id = props.location.state.house_id ? props.location.state.house_id : '';
  const [transaction, setTransaction] = useState(true);
  const [deletedTransaction, setDeletedTransaction] = useState(true);
  const [is_deleted, setIs_deleted] = useState();
  const [loanclosuredate, setLoanclosuredate] = useState('');
  const [loanHouse_id, setLoanHouse_id] = useState('');
  const [transactionData, setTransactionData] = useState();

  useEffect(() => {
    var activeDatesArr = [];
    if (props.loanDetails && props.loanDetails.length > 0) {
      setLoanHouse_id(props.loanDetails[0].house_id);
      props.loanDetails.map(data => {
        let expiry = Math.floor(new Date(data.loanclosuredate).getTime() / 86400000);
        let current = Math.floor(new Date().getTime() / 86400000);
        if (expiry > current) {
          activeDatesArr.push(expiry);
          setLoanclosuredate(activeDatesArr);
        }
      });
    }
  }, [props.loanDetails]);

  useEffect(() => {
    if (props.transactions && props.transactions.length > 0) {
      setTransactionData(props.transactions);
    }
  }, [props.transactions]);

  const handleDocType = type => {
    if (type === '1') {
      setTransaction(true);
      let data = {
        house_id: house_id,
      };
      props.getTransaction(data);
    } else {
      setTransaction(false);
      setDeletedTransaction(true);
      let data = {
        house_id: house_id,
      };
      props.getTransactionAllData(data);
    }
  };

  const handleUndelete = (Id, houseId, tabType) => {
    handleDocType(tabType);
    let data = {
      id: Id,
      house_id: house_id,
    };
    props.unDeleteTransaction(data);
  };
  const header = [
    'Account Name',
    'Transaction Date',
    'Contact Person',
    'Type',
    'Amount',
    'Comments',
    'Receipt',
    'Entry Date & Time',
    'Entered By',
  ];
  var columns = [
    {
      name: 'Account Name',
      selector: 'companyname',
      sortable: true,
      cell: row =>
        row.ltransaction ? (
          row.companyname
        ) : (
          <Link
            data-tag="allowRowEvents"
            role="link"
            to={{ pathname: `/transaction/${house_id}`, state: { house_id: house_id } }}
          >
            {row.account_name.includes('-') ? row.account_name.split('-')[1] : row.account_name}
          </Link>
        ),
    },
    {
      name: 'Transaction Date',
      selector: 'date',
      sortable: true,
      cell: row => Util.dateFormat(row.date),
    },
    { name: 'Contact Person', selector: 'contact_person', sortable: true },
    {
      name: 'Type',
      selector: 'type',
      sortable: true,
      cell: row => <span className="text-capitalize">{row.type}</span>,
    },
    { name: 'Amount', selector: 'amount', sortable: true },
    { name: 'Comments', selector: 'comments', sortable: true },
    { name: 'Principal', selector: 'principal', sortable: true },
    { name: 'Interest', selector: 'interest', sortable: true },
    {
      name: 'Entry Date & Time',
      selector: 'created_at',
      sortable: true,
      cell: row => Util.dateFormatWithTime(row.created_at),
    },
    {
      name: 'Entered By',
      selector: 'contact_person',
      sortable: true,
      cell: row => <span>{'*' + row.contact_person}</span>,
    },
  ];

  const headerOfDel_Data = [
    'Account Name',
    'Transaction Date',
    'Contact Person',
    'Type',
    'Amount',
    'Comments',
    'Receipt',
    'Entry Date & Time',
    'Entered By',
    'Actions',
  ];

  var columnsOfDel_Data = [
    {
      name: 'Account Name',
      selector: 'account_name',
      sortable: true,
      cell: row =>
        row.ltransaction == '*' ? (
          row.companyname + '*'
        ) : (
          <span>{row.account_name.split('-')[1]}</span>
        ),
    },
    {
      name: 'Transaction Date',
      selector: 'date',
      sortable: true,
      cell: row => Util.dateFormat(row.date),
    },
    { name: 'Contact Person', selector: 'contact_person', sortable: true },
    { name: 'Type', selector: 'type', sortable: true },
    { name: 'Amount', selector: 'amount', sortable: true },
    { name: 'Comments', selector: 'comments', sortable: true },
    {
      name: 'Receipt',
      selector: 'receipt',
      sortable: true,
      cell: row => {
        if (row && row.receipt) {
          let sp = row.receipt.split('/');
          if (sp && sp.length > 3) {
            row.receipt = sp[4].slice(4);
          }
        }
      },
    },
    {
      name: 'Entry Date & Time',
      selector: 'created_at',
      sortable: true,
      cell: row => Util.dateFormatWithTime(row.created_at),
    },
    { name: 'Entered By', selector: 'entered_by', sortable: true },
    {
      name: 'Actions',
      selector: 'house_id',
      sortable: true,
      cell: row => (
        <button
          className="btn btn-primary  addNewItem"
          onClick={() => handleUndelete(row.id, house_id, 'Deleted Transactions')}
        >
          Undelete
        </button>
      ),
    },
  ];

  const addNewTransaction = () => {
    props.resetTransactionDetails();
    props.history.push({
      pathname: `/transaction/${house_id}`,
      state: { house_id: house_id },
    });
  };

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Transactions</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Transactions)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row">
            <div className="col-md-12 text-align-left">
              <span
                className={transaction ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('1')}
              >
                Transactions
              </span>
              <span
                className={!transaction ? 'active-bar mr-50' : 'mr-50'}
                onClick={e => handleDocType('2')}
              >
                Deleted Transactions
              </span>
            </div>
          </div>
          {transaction ? (
            <React.Fragment>
              {transactionData !== undefined && (
                <Table
                  header={header}
                  columns={columns}
                  url={'/transaction'}
                  getSingleData={props.getSingleTransaction}
                  tableId="transaction"
                  data={transactionData}
                />
              )}
            </React.Fragment>
          ) : (
            <Table
              header={headerOfDel_Data}
              columns={columnsOfDel_Data}
              getSingleData={props.getSingleTransaction}
              tableId="transactionid"
              data={props.transactionAllData}
            />
          )}
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12 right-button">
          {loanHouse_id === house_id && loanclosuredate !== '' ? (
            <button className="btn btn-secondary btn-sm" onClick={addNewTransaction}>
              <span className="glyphicon glyphicon-plus"> </span> Add Transaction
            </button>
          ) : (
            <React.Fragment>
              You are not allowed for New Transaction Because Your Loan is Closed
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  transactions: state.Transaction.transactions.data,
  transactionAllData: state.Transaction.transactionDeletedData.data,
  loanTransaction: state.Loan.mortgageTransaction.data,
  loanDetails: state.Loan.loans.data,
  contactList: state.Contact.contacts.data,
  isLoading: state.Spinner.isTransactionLoading,
});

const mapDispatchToProps = {
  getTransaction,
  getSingleTransaction,
  getTransactionAllData,
  unDeleteTransaction,
  getContact,
  resetTransactionDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionList);
