import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import { Link } from 'react-router-dom';
import { getLease, getSingleLease, resetLeaseDetails } from '../../store/Actions/Lease';
import { getContact } from '../../store/Actions/contact';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const LeaseList = props => {
  const [leaseData, setLeaseData] = useState();

  useEffect(() => {
    if (props && props.leases && props.leases.length > 0) {
      props.contactList.map(item1 => {
        const selectedIndex = props.leases.findIndex(
          val => item1.id === parseInt(val.tenant_name1.split('-')[0])
        );
        if (selectedIndex > -1 && selectedIndex !== undefined) {
          props.leases[selectedIndex].tenant_phone1 = item1.phone1;
          props.leases[selectedIndex].tenant_email1 = item1.email;
        }
      });
      setLeaseData(props.leases);
    } else {
      let data = {
        house_id: house_id,
      };
      props.getLease(data);
      setLeaseData(props.leases);
    }
  }, [props.leases, props.contactList]);

  let house_id = props.location.state.house_id ? props.location.state.house_id : '';

  const header = [
    'Lease Begin Date',
    'Lease End Date',
    'Rent/Month',
    'Rent Due By',
    'Rental Insurance',
  ];

  var columns = [
    {
      name: 'Tenant Name',
      selector: 'tenant_name1',
      sortable: true,
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          role="link"
          to={{ pathname: `/tenant/${house_id}`, state: { house_id: house_id } }}
        >
          {row.tenant_name1 && row.tenant_name1.split('-')[1]}
        </Link>
      ),
    },
    { name: 'Tenant Phono No.', selector: 'tenant_phone1', sortable: true },
    {
      name: 'Lease Begin Date',
      selector: 'lease_begin',
      sortable: true,
      cell: row => Util.dateFormat(row.lease_begin),
    },
    {
      name: 'Lease End Date',
      selector: 'lease_end',
      sortable: true,
      cell: row => Util.dateFormat(row.lease_end),
    },
    { name: 'Security Deposit($)', selector: 'deposit', sortable: true, cell: row => row.deposit },
    { name: 'Amount($)', selector: 'rent', sortable: true, cell: row => row.rent },
    { name: 'Frequency', selector: 'frequency', sortable: true },
    { name: 'Rental Insurance', selector: 'rental_insurance', sortable: true },
    { name: 'space Name', selector: 'hmo_space', sortable: true },
    { name: 'Space Description', selector: 'space_description', sortable: true },
    { name: 'Lease Document', selector: '', sortable: true },
    { name: 'Status', selector: 'status', sortable: true },
  ];

  const addNewLease = () => {
    props.resetLeaseDetails();
    props.history.push({
      pathname: `/tenant/${house_id}`,
      state: { house_id: house_id },
    });
  };

  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Lease Details</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Lease)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          {leaseData !== undefined && (
            <Table
              header={header}
              url={'/tenant'}
              columns={columns}
              getSingleData={props.getSingleLease}
              tableId="lease"
              data={leaseData}
              house_id={house_id}
            />
          )}
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12 right-button">
          <button className="btn btn-secondary btn-sm" onClick={addNewLease}>
            <span className="glyphicon glyphicon-plus"> </span> Add New Lease
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  leases: state.Lease.leases.data,
  contactList: state.Contact.contacts.data,
  isLoading: state.Spinner.isLeaseLoading,
});

const mapDispatchToProps = {
  getLease,
  getContact,
  getSingleLease,
  resetLeaseDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaseList);
