import React, { useParams } from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import { Link } from 'react-router-dom';
import { getLoan, getSingleLoan, resetLoanDetails } from '../../store/Actions/Loan';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const LoanList = props => {
  
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';

  const header = [
    'Type',
    'Lender Company',
    'Loan Number',
    'Loan Amount',
    'Interest Rate(%)',
    'Term',
    'Escrow',
    'Property Tax',
    'Start Date',
    'End Date',
    'Status',
  ];

  var columns = [
    {
      name: 'Lender Company',
      selector: 'companyname',
      sortable: true,
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          role="link"
          to={{ pathname: `/loan-lender/${house_id}`, state: { house_id: house_id } }}
        >
          {row.companyname}
        </Link>
      ),
    },
    {
      name: 'Type',
      selector: 'loantype',
      sortable: true,
    },
    { name: 'Loan Number', selector: 'loannumber', sortable: true },
    {
      name: 'Loan Amount',
      selector: 'loanamount',
      sortable: true,
      cell: row => Util.addCommasList(row.loanamount),
    },
    { name: 'Interest Rate(%)', selector: 'rateofinterest', sortable: true },
    { name: 'Term', selector: 'loanterm', sortable: true },
    { name: 'Escrow', selector: 'mortgage', sortable: true },
    {
      name: 'Property Tax',
      selector: 'propertytax',
      sortable: true,
      cell: row => Util.addCommasList(row.propertytax),
    },
    {
      name: 'Start Date',
      selector: 'loanbegindate',
      sortable: true,
      cell: row => Util.dateFormat(row.loanbegindate),
    },
    {
      name: 'End Date',
      selector: 'loanclosuredate',
      sortable: true,
      cell: row => Util.dateFormat(row.loanclosuredate),
    },
    {
      name: 'Renewal Maturity Date',
      selector: 'renewal_maturity_date',
      sortable: true,
      cell: row => Util.dateFormat(row.renewal_maturity_date),
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: true,
      cell: row =>
        row.status === 'Active' ? (
          <span className="active_status">{row.status}</span>
        ) : row.status === 'Renewal' ? (
          <span className="renewed_status">{row.status}</span>
        ) : row.status === 'Closed' ? (
          <span className="expired_status">{row.status}</span>
        ) : row.status === 'Foreclosed' ? (
          <span className="foreclosed_status">{row.status}</span>
        ) : (
          ''
        ),
    },
    {
      name: 'Amortization Table',
      selector: 'amortization',
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          className="glyphicon glyphicon-tasks"
          role="link"
          to={{ pathname: `/amortization/${row.id}`, state: { id: row.id } }}
        ></Link>
      ),
    },
  ];

  const addNewLoan = () => {
    props.resetLoanDetails();
    props.history.push({
      pathname: `/loan-lender/${house_id}`,
      state: { house_id: house_id },
    });
  };

  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Loan Details List</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Loans)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <Table
            header={header}
            url={'/loan-lender'}
            columns={columns}
            getSingleData={props.getSingleLoan}
            tableId="loan"
            data={props.loans}
            house_id={house_id}
          />
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-10"></div>
        <div className="col-sm-2 right-button">
          <button className="btn btn-secondary btn-sm" onClick={addNewLoan}>
            <span className="glyphicon glyphicon-plus"> </span> Add New Loan
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  loans: state.Loan.loans.data,
  isLoading: state.Spinner.isLoanLoading,
});

const mapDispatchToProps = {
  getLoan,
  getSingleLoan,
  resetLoanDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanList);
