export const USER_SIGNUP = 'USER_SIGNUP';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RESPONSE_ERROR = 'RESPONSE_ERROR';
export const LOGIN_USER = 'LOGIN_USER';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESPONSE_SUCCESS = 'RESPONSE_SUCCESS';
export const ADMIN_USER = 'ADMIN_USER';
export const ROLE_OF_USER = 'ROLE_OF_USER';
export const GET_USER_All = 'GET_USER_All';
export const DELETE_USER = 'DELETE_USER';
export const DEACTIVE_USER = 'DEACTIVE_USER';
export const ACTIVE_USER = 'ACTIVE_USER';
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const ACCOUNT_STATUS = 'ACCOUNT_STATUS';
export const USER_SPINNER = 'USER_SPINNER';

export const GET_HOUSES = 'GET_HOUSES';
export const CREATE_HOUSE = 'CREATE_HOUSE';
export const ADD_TITLE_HOLDER = 'ADD_TITLE_HOLDER';
export const ADD_HOA_DETAILS = 'ADD_HOA_DETAILS';
export const ADD_REALTOR_DETAILS = 'ADD_REALTOR_DETAILS';
export const ADD_HMO_SPACE = 'ADD_HMO_SPACE';
export const GET_HOME_DETAIL = 'GET_HOME_DETAIL';
export const GET_OWNER_HOME = 'GET_OWNER_HOME';
export const DELETE_HOUSE = 'DELETE_HOUSE';
export const DELETE_HMO = 'DELETE_HMO';
export const DELETE_HOUSE_ATTACHMENT = 'DELETE_HOUSE_ATTACHMENT';
export const DELETE_REALTOR = 'DELETE_REALTOR';
export const GET_HOUSE_HMO = 'GET_HOUSE_HMO';
export const HOUSE_SPINNER = 'HOUSE_SPINNER';

export const ADD_CONTACT_DETAILS = 'ADD_CONTACT_DETAILS';
export const GET_CONTACT_DETAILS = 'GET_CONTACT_DETAILS';
export const ADD_GROUP = 'ADD_GROUP';
export const GET_GROUP = 'GET_GROUP';
export const GET_CONTACTS = 'GET_CONTACTS';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const CONTACT_SPINNER = 'CONTACT_SPINNER';
export const CONTACT_SPINNER1 = 'CONTACT_SPINNER1';

export const ADD_LOAN = 'ADD_LOAN';
export const GET_LOAN = 'GET_LOAN';
export const GET_COUNTRY_STATE = 'GET_COUNTRY_STATE';
export const GET_SINGLE_LOAN = 'GET_SINGLE_LOAN';
export const RESET_SINGLE_LOAN = 'RESET_SINGLE_LOAN';
export const DELETE_LOAN = 'DELETE_LOAN';
export const LOAN_SPINNER = 'LOAN_SPINNER';
export const LOAN_SPINNER1 = 'LOAN_SPINNER1';

export const ADD_INSURANCE = 'ADD_INSURANCE';
export const GET_INSURANCE = 'GET_INSURANCE';
export const GET_SINGLE_INSURANCE = 'GET_SINGLE_INSURANCE';
export const DELETE_INSURANCE = 'DELETE_INSURANCE';
export const INSURANCE_SPINNER = 'INSURANCE_SPINNER';

export const ADD_INCIDENT = 'ADD_INCIDENT';
export const GET_INCIDENT = 'GET_INCIDENT';
export const GET_INCIDENT_DETAILS = 'GET_INCIDENT_DETAILS';
export const DELETE_INCIDENT = 'DELETE_INCIDENT';
export const INCIDENT_STATUS = 'INCIDENT_STATUS';
export const INCIDENT_SPINNER = 'INCIDENT_SPINNER';

export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_SINGLE_TRANSACTION = 'GET_SINGLE_TRANSACTION';
export const GET_TRANSACTION_All = 'GET_TRANSACTION_All';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const UNDELETE_TRANSACTION = 'UNDELETE_TRANSACTION';
export const ADD_LOAN_TRANSACTION = 'ADD_LOAN_TRANSACTION';
export const GET_LOAN_TRANSACTION = 'GET_LOAN_TRANSACTION';
export const ADD_LOAN_AMORTIZATION = 'ADD_LOAN_AMORTIZATION';
export const TRANSACTION_SPINNER = 'TRANSACTION_SPINNER';

export const ADD_WARRANTY = 'ADD_WARRANTY';
export const GET_WARRANTY = 'GET_WARRANTY';
export const GET_SINGLE_WARRANTY = 'GET_SINGLE_WARRANTY';
export const DELETE_WARRANTY = 'DELETE_WARRANTY';
export const WARRANTY_SPINNER = 'WARRANTY_SPINNER';

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const ACCOUNT_SPINNER = 'ACCOUNT_SPINNER';

export const ADD_REFERENCE = 'ADD_REFERENCE';
export const GET_REFERENCES = 'GET_REFERENCES';
export const GET_SINGLE_REFERENCE = 'GET_SINGLE_REFERENCE';
export const REFERENCE_SPINNER = 'REFERENCE_SPINNER';

export const ADD_DOCUMENT = 'ADD_DOCUMENT';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_SINGLE_DOCUMENT = 'GET_SINGLE_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DOCUMENT_SPINNER = 'DOCUMENT_SPINNER';

export const ADD_LINK = 'ADD_LINK';
export const GET_LINK = 'GET_LINK';
export const GET_SINGLE_LINK = 'GET_SINGLE_LINK';
export const DELETE_LINK = 'DELETE_LINK';
export const LINK_SPINNER = 'LINK_SPINNER';

export const ADD_GALLERY = 'ADD_GALLERY';
export const GET_GALLERY = 'GET_GALLERY';
export const GET_SINGLE_GALLERY = 'GET_SINGLE_GALLERY';
export const DELETE_GALLERY = 'DELETE_GALLERY';
export const GALLERY_SPINNER = 'GALLERY_SPINNER';

export const ADD_SHARE_DETAILS = 'ADD_SHARE_DETAILS';
export const GET_SHARE_DETAILS = 'GET_SHARE_DETAILS';
export const GET_SHARE = 'GET_SHARE';
export const DELETE_SHARE = 'DELETE_SHARE';
export const SHARE_SPINNER = 'SHARE_SPINNER';

export const ADD_LEASE = 'ADD_LEASE';
export const GET_LEASE = 'GET_LEASE';
export const GET_SINGLE_LEASE = 'GET_SINGLE_LEASE';
export const DELETE_LEASE = 'DELETE_LEASE';
export const LEASE_SPINNER = 'LEASE_SPINNER';

export const GET_MORTGAGE_TRANSACTION = 'GET_MORTGAGE_TRANSACTION';

export const ADD_EVENT = 'ADD_EVENT';
export const GET_EVENT = 'GET_EVENT';
export const DELETE_EVENT = 'DELETE_EVENT';
export const EVENT_SPINNER = 'EVENT_SPINNER';
