import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import '../../style/Loan.css';
import { getSingleLoan, addLoanAmortization } from '../../store/Actions/Loan';
import Table from '../../Reusable/Table';
import { Util, InfoLinks } from '../../Datamanipulation/Util';
import { getContact } from '../../store/Actions/contact';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const Homecost = props => {
  const [purchaseprice, setPurchaseprice] = useState();
  const [homecost, setHomecost] = useState();
  const [tableData, setTableData] = useState([]);
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';
  const header = [
    'Type',
    'Lender Name',
    'Loan Number',
    'Loan Amount',
    'Interest Rate(%)',
    'Term',
    'Escrow',
    'Property Tax',
    'Start Date',
    'End Date',
    'Status',
  ];
  var columns = [
    {
      name: 'Transaction Date',
      selector: 'transactiondate',
      sortable: true,
      cell: row => Util.dateFormat(row.transactiondate),
    },
    { name: 'Category', selector: 'category', sortable: true },
    { name: 'Payee', selector: 'payee', sortable: true },
    { name: 'Description', selector: 'description', sortable: true },
    {
      name: 'Expenses',
      selector: 'credit',
      sortable: true,
      cell: row => (row.credit === 'NaN' ? '0.00' : row.credit),
    },
    {
      name: 'Income',
      selector: 'debit',
      sortable: true,
      cell: row => (row.debit === 'NaN' ? '0.00' : row.debit),
    },
    {
      name: 'Home Cost',
      selector: 'homecost',
      sortable: true,
      cell: row => (row.homecost === 'NaN' ? '0.00' : row.homecost),
    },
  ];

  useEffect(() => {
    if (props.houseDetails && props.houseDetails.house.length > 0) {
      setPurchaseprice(Util.addCommasList(props.houseDetails.house[0]['purchaseamount']));
      setHomecost(Util.addCommasList(props.houseDetails.house[0]['purchaseamount']));
    }
    let tableData = [];
    if (props.transactions && props.transactions.length > 0) {
      let transaction = props.transactions.filter(filter => filter.account_name);
      for (let i = 0; i < transaction.length; i++) {
        let homecost = 0;
        if (i == 0) {
          homecost = Util.removeCommas(
            props.houseDetails.house[0]['purchaseamount']
              ? props.houseDetails.house[0]['purchaseamount']
              : 0
          );
        } else {
          homecost = Util.removeCommas(
            tableData[i - 1]['homecost'] ? tableData[i - 1]['homecost'] : 0
          );
        }
        let amount = Util.removeCommas(transaction[i]['amount'] ? transaction[i]['amount'] : 0);

        if (transaction[i]['type'] == 'Payment') {
          homecost = parseFloat(homecost) + parseFloat(amount);
        } else if (transaction[i]['type'] == 'Receipt') {
          homecost = parseFloat(homecost) - parseFloat(amount);
        }

        let category = transaction[i]['groupname'].split('&');
        let data = {
          transactiondate: transaction[i]['date'],
          category: category[category.length - 1],
          payee: transaction[i]['companyname'],
          description: transaction[i]['comments'],
          credit: transaction[i]['type'] == 'Payment' ? Util.addCommasList(amount) : '',
          debit: transaction[i]['type'] == 'Receipt' ? Util.addCommasList(amount) : '',
          homecost: Util.addCommasList(parseFloat(homecost).toFixed(2)),
        };
        tableData.push(data);
      }

      if (props.loanTransactions && props.loanTransactions.length > 0) {
        for (let i = 0; i < props.loanTransactions.length; i++) {
          let homecost = 0;
          if (i == 0 && tableData.length == 0) {
            homecost = Util.removeCommas(
              props.houseDetails.house[0]['purchaseamount']
                ? props.houseDetails.house[0]['purchaseamount']
                : 0
            );
          } else {
            homecost = Util.removeCommas(
              tableData[tableData.length - 1]['homecost']
                ? tableData[tableData.length - 1]['homecost']
                : 0
            );
          }
          let amount = Util.removeCommas(props.loanTransactions[i]['interest']);
          homecost = parseFloat(homecost) + parseFloat(amount);

          let category = props.loanTransactions[i]['groupname'].split('&');
          let data = {
            transactiondate: props.loanTransactions[i]['paymentdate'],
            category: category[category.length - 1],
            payee: props.loanTransactions[i]['companyname'],
            description: '',
            credit: Util.addCommasList(parseFloat(amount).toFixed(2)),
            debit: '',
            homecost: Util.addCommasList(parseFloat(homecost).toFixed(2)),
          };
          tableData.push(data);
        }
      }
      if (tableData.length > 0) {
        setHomecost(tableData[tableData.length - 1]['homecost']);
      }
    }
    setTableData(tableData);
  }, [props.houseDetails, props.loanTransactions, props.transactions]);
  return (
    <div className="container-fluid loan">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Home Cost Details</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.HomeCost)}
        ></i>
      </div>
      <div className="inner-container full">
        <div className="inner-form">
          <div className="purchagePrice">
            <h2>Purchase Price : {purchaseprice}</h2>
            <h2>Home Cost: {homecost === 'NaN' ? '0.00' : homecost}</h2>
          </div>
          <Table
            header={header}
            url={'/loan-lender'}
            columns={columns}
            getSingleData={props.getSingleLoan}
            tableId="amortization"
            data={tableData}
            house_id={house_id}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  contacts: state.Contact.contacts.data,
  loanDetails: state.Loan.loanDetails.data,
  houseDetails: state.House.houseDetail.data,
  transactions: state.Transaction.transactions.data,
  loanTransactions: state.Loan.mortgageTransaction.data,
  isLoading: state.Spinner.isContactLoading,
});

const mapDispatchToProps = {
  getSingleLoan,
  addLoanAmortization,
  getContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Homecost);
