const dev = {
  REACT_APP_API_URL: 'http://localhost:4600/',
  REACT_APP_S3_CLIENT_CONFIG: {
    bucketName: 'myhomeinfodata',
    dirName: '',
    region: 'us-west-2',
    accessKeyId: 'AKIAW4MIDXMB2PMHHBW6',
    s3Url: 'https://myhomeinfodata.s3.amazonaws.com',
    secretAccessKey: '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',
  },
};

const prod = {
  REACT_APP_API_URL: 'https://api.myhomemgmt.net/',
  REACT_APP_S3_CLIENT_CONFIG: {
    bucketName: 'myhomemgmt-prod-data',
    dirName: '',
    region: 'us-east-1',
    accessKeyId: 'AKIAW4MIDXMB2PMHHBW6',
    s3Url: 'https://myhomemgmt-prod-data.s3.amazonaws.com',
    secretAccessKey: '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',
  },
};

const qa = {
  REACT_APP_API_URL: 'https://dev.api.myhomemgmt.net/',
  REACT_APP_S3_CLIENT_CONFIG: {
    bucketName: 'myhomeinfodata',
    dirName: '',
    region: 'us-west-2',
    accessKeyId: 'AKIAW4MIDXMB2PMHHBW6',
    s3Url: 'https://myhomeinfodata.s3.amazonaws.com',
    secretAccessKey: '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',
  },
};

const config = {
  ...(process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : process.env.REACT_APP_STAGE === 'qa'
    ? qa
    : dev),
};

export default config;
