import { Document } from '../../api/api';
import {
  ADD_DOCUMENT,
  GET_DOCUMENT,
  GET_SINGLE_DOCUMENT,
  DELETE_DOCUMENT,
  DOCUMENT_SPINNER,
} from '../actionTypes';
import { NotificationManager } from 'react-notifications';
import { ShowSpinner, HideSpinner } from '../commondispatch';

export const addDocument = data => {
  return async dispatch => {
    ShowSpinner(dispatch, DOCUMENT_SPINNER);
    await Document.addDocument(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: ADD_DOCUMENT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, DOCUMENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, DOCUMENT_SPINNER);
        throw error;
      });
  };
};

export const getDocument = data => {
  return async dispatch => {
    ShowSpinner(dispatch, DOCUMENT_SPINNER);
    await Document.getDocument(data)
      .then(res => {
        if ((res && res.status === 200) || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_DOCUMENT,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, DOCUMENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, DOCUMENT_SPINNER);
        throw error;
      });
  };
};

export const getSingleDocument = data => {
  return async dispatch => {
    ShowSpinner(dispatch, DOCUMENT_SPINNER);
    await Document.getSingleDocument(data)
      .then(res => {
        if ((res && res.status === 200) || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_SINGLE_DOCUMENT,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, DOCUMENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, DOCUMENT_SPINNER);
        throw error;
      });
  };
};

export const deleteDocument = data => {
  return async dispatch => {
    ShowSpinner(dispatch, DOCUMENT_SPINNER);
    await Document.deleteDocument(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: DELETE_DOCUMENT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, DOCUMENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, DOCUMENT_SPINNER);
        throw error;
      });
  };
};

export const resetFolderDetails = () => {
  return dispatch => {
    var data = {
      type: GET_SINGLE_DOCUMENT,
      payload: { data: null },
    };
    dispatch(data);
  };
};
