import { Contact } from '../../api/api';
import {
  GET_CONTACTS,
  ADD_GROUP,
  GET_GROUP,
  GET_CONTACT_DETAILS,
  DELETE_CONTACT,
  CONTACT_SPINNER,
} from '../actionTypes';
import { NotificationManager } from 'react-notifications';
import { ShowSpinner, HideSpinner } from '../commondispatch';

export const addContact = data => {
  return async dispatch => {
    ShowSpinner(dispatch, CONTACT_SPINNER);
    await Contact.addContact(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_CONTACT_DETAILS,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, CONTACT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, CONTACT_SPINNER);
        throw error;
      });
  };
};

export const getContact = data => {
  return async dispatch => {
    ShowSpinner(dispatch, CONTACT_SPINNER);
    await Contact.getContact(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422 || res.status === 204) {
          var data = {
            type: GET_CONTACTS,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, CONTACT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, CONTACT_SPINNER);
        throw error;
      });
  };
};

export const resetContactDetails = () => {
  return dispatch => {
    var data = {
      type: GET_CONTACT_DETAILS,
      payload: { data: null },
    };
    dispatch(data);
  };
};

export const addGroup = data => {
  return async dispatch => {
    ShowSpinner(dispatch, CONTACT_SPINNER);
    await Contact.addGroup(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: ADD_GROUP,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, CONTACT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, CONTACT_SPINNER);
        throw error;
      });
  };
};

export const getGroup = data => {
  return async dispatch => {
    ShowSpinner(dispatch, CONTACT_SPINNER);
    await Contact.getGroup(data)
      .then(res => {
        if (res && res.status && (res.status === 200 || res.status === 404 || res.status === 422)) {
          var data = {
            type: GET_GROUP,
            payload: res,
          };
          dispatch(data);
          // NotificationManager.success("Success Message", res.statusText);
        } else if (res && res.statusText) {
          NotificationManager.error('Error Message to fetch group details', res.statusText);
        }
        HideSpinner(dispatch, CONTACT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, CONTACT_SPINNER);
        throw error;
      });
  };
};

export const getContactById = data => {
  return async dispatch => {
    ShowSpinner(dispatch, CONTACT_SPINNER);
    await Contact.getContactById(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422 || res.status === 204) {
          var data = {
            type: GET_CONTACT_DETAILS,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, CONTACT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, CONTACT_SPINNER);
        throw error;
      });
  };
};

export const getContactForTransaction = data => {
  return async dispatch => {
    ShowSpinner(dispatch, CONTACT_SPINNER);
    await Contact.getContactForTransaction(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_CONTACTS,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, CONTACT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, CONTACT_SPINNER);
        throw error;
      });
  };
};

export const deleteContact = data => {
  return async dispatch => {
    ShowSpinner(dispatch, CONTACT_SPINNER);
    await Contact.deleteContact(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422 || res.status === 204) {
          var data = {
            type: DELETE_CONTACT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, CONTACT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, CONTACT_SPINNER);
        throw error;
      });
  };
};
