import { GET_ACCOUNT, UPDATE_ACCOUNT, CHANGE_PASSWORD, ACCOUNT_STATUS } from '../actionTypes';

const initialState = {
  accountDetails: {},
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return {
        ...state,
        accountDetails: action.payload,
      };
    case GET_ACCOUNT:
      return {
        ...state,
        accountDetails: action.payload,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        user: action.payload,
      };
    case ACCOUNT_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};

export default accountReducer;
