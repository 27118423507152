import React, { useState, useEffect } from 'react';
import '../../style/authentication.css';
import { NotificationManager } from 'react-notifications';
import Leftbar from './leftbar';
import { resetPassword } from '../../store/Actions/Authentication';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const ResetPassword = props => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState(new URLSearchParams(props.location.search).get('email'));

  const handleSubmit = async () => {
    const hashNewPassword = Buffer.from(confirmPassword, 'utf8').toString('base64');
    const emailhash = new URLSearchParams(props.location.search).get('token');
    let data = {
      email: email,
      password: hashNewPassword,
      emailhash: emailhash,
    };
    let valid = validate();
    if (valid) {
      props.resetPassword(data);
    }
  };

  useEffect(() => {
    if (props.user.status === 200) {
      setTimeout(() => {
        props.history.push('/');
      }, 2000);
    }
  }, [props.user]);

  const validate = () => {
    if (email.length === 0) {
      NotificationManager.error('Error message', 'Invalid URL.');
      return false;
    } else if (newPassword.length === 0) {
      NotificationManager.error('Error message', 'New password cannot be empty.');
      return false;
    } else if (newPassword.length < 6) {
      NotificationManager.error(
        'Error message',
        'New password should be atleast 6 character long.'
      );
      return false;
    } else if (newPassword != confirmPassword) {
      NotificationManager.error('Error message', 'New password and confirm password are not same.');
      return false;
    }
    return true;
  };

  return (
    <div className="row full-container" id="login-row">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="col-md-6 login_left">
        <Leftbar />
      </div>

      <div className="col-md-6 login_right">
        <div className="row login-container">
          <div className="col-md-3"></div>
          <div className="col-md-6">
            <h4 className="tc pb-30">Reset Password</h4>

            <div className="form-group">
              <label htmlFor="email" className="req">
                Email
              </label>
              <input
                type="email"
                placeholder="Please Enter Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                readOnly="true"
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="newPassword" className="req">
                New Password
              </label>
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <label htmlFor="confirmPassword" className="req">
                Confirm Password
              </label>
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                className="form-control"
              />
            </div>

            <div className="form-group">
              <buttom className="btn login-btn fb" onClick={handleSubmit}>
                Submit
              </buttom>
            </div>

            <div className="form-group tc">
              <Link to="/" className="primary-button">
                Go to Sign in page
              </Link>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.Authentication.user,
  isLoading: state.Spinner.isUserLoading,
});

const mapDispatchToProps = {
  resetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
