import { Reminder } from '../../api/api';
import { ADD_EVENT, GET_EVENT, DELETE_EVENT, EVENT_SPINNER } from '../actionTypes';
import { NotificationManager } from 'react-notifications';
import { ShowSpinner, HideSpinner } from '../commondispatch';

export const addEvent = data => {
  return async dispatch => {
    ShowSpinner(dispatch, EVENT_SPINNER);
    await Reminder.addEvent(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: ADD_EVENT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, EVENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, EVENT_SPINNER);
        throw error;
      });
  };
};

export const getEvent = data => {
  return async dispatch => {
    ShowSpinner(dispatch, EVENT_SPINNER);
    await Reminder.getEvent(data)
      .then(res => {
        if ((res && res.status === 200) || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_EVENT,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, EVENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, EVENT_SPINNER);
        throw error;
      });
  };
};

export const deleteEvent = data => {
  return async dispatch => {
    ShowSpinner(dispatch, EVENT_SPINNER);
    await Reminder.deleteEvent(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: DELETE_EVENT,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, EVENT_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, EVENT_SPINNER);
        throw error;
      });
  };
};
