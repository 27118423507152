import {
  ADD_CONTACT_DETAILS,
  GET_CONTACT_DETAILS,
  ADD_GROUP,
  GET_GROUP,
  GET_CONTACTS,
  DELETE_CONTACT,
} from '../actionTypes';

const initialState = {
  contacts: {},
  contactDetails: {},
  groups: {},
  groupDetails: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_CONTACT_DETAILS:
      return {
        ...state,
        contacts: action.payload,
      };
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload,
      };
    case GET_CONTACT_DETAILS:
      return {
        ...state,
        contactDetails: action.payload,
      };
    case ADD_GROUP:
      return {
        ...state,
        groupDetails: action.payload,
      };
    case GET_GROUP:
      return {
        ...state,
        groups: action.payload,
      };
    case DELETE_CONTACT:
      return {
        ...state,
        contacts: action.payload,
      };
    default:
      return state;
  }
}
