import React, { useEffect } from 'react';
import '../../style/Contact.css';
import { Link } from 'react-router-dom';
import { getIncident, getIncidentById, clearIncidentStatus } from '../../store/Actions/Incident';
import { connect } from 'react-redux';
import Table from '../../Reusable/Table';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const IncidentList = props => {
  const userDetails = JSON.parse(localStorage.getItem('user'));
  const header = ['Feature Name', 'Title', 'Criticality', 'Contact Number', 'Time To Contact'];
  var columns = [
    {
      name: 'Feature Name',
      selector: 'featurename',
      sortable: true,
      cell: row => (
        <Link data-tag="allowRowEvents" role="link" to={{ pathname: 'incident' }}>
          {row.featurename}
        </Link>
      ),
    },
    { name: 'User Name', selector: 'username', sortable: true },
    { name: 'Created On', selector: 'createdon', sortable: true },
    { name: 'Title', selector: 'title', sortable: true },
    {
      name: 'Criticality',
      selector: 'criticality',
      sortable: true,
    },
    { name: 'Contact Number', selector: 'contactphone', sortable: true },
    { name: 'Time To Contact', selector: 'timetocontact', sortable: true },
    { name: 'Status', selector: 'status', sortable: true },
  ];

  useEffect(() => {
    props.clearIncidentStatus();
    if (!props.incidents) {
      const data = {
        userid: userDetails.id,
        userrole: userDetails.role,
      };
      props.getIncident(data);
    }
  }, [props.incidents]);

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Incidents</span>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <Table
            url={'/incident'}
            data={props.incidents}
            columns={columns}
            header={header}
            getSingleData={props.getIncidentById}
            tableId={'incident'}
          />
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-10"></div>
        <div className="col-sm-2 right-button">
          <Link
            to={{
              pathname: `/incident`,
            }}
            className="btn btn-secondary btn-sm"
            role="button"
          >
            <span className="glyphicon glyphicon-plus"> </span> Add New Incident
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  incidents: state.Incident.incidents.data,
  isLoading: state.Spinner.isIncidentLoading,
});

const mapDispatchToProps = {
  getIncident,
  getIncidentById,
  clearIncidentStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentList);
