import React, { useState, useEffect } from 'react';
import '../../style/Contact.css';
import { addLink, deleteLink } from '../../store/Actions/Link';
import { connect } from 'react-redux';
import { Util } from '../../Datamanipulation/Util';
import { useLocation } from 'react-router-dom';
import { Link as LinkButton } from 'react-router-dom';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import { NotificationManager } from 'react-notifications';
import { sortBy } from 'lodash';
import ConfirmModal from '../../Reusable/Confirm-Modal';

const Link = props => {
  const location = useLocation();
  let houseId = location.state.house_id ? location.state.house_id : '';

  const [date, setDate] = useState(Util.getCurrentDate('-'));
  const [groupname, setGroupname] = useState('');
  const [urlname, setUrlname] = useState('');
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');
  const [house_id, setHouse_id] = useState(houseId);
  const [category, setCategory] = useState('Home');
  const categoryList = sortBy(Object.keys(Util.linkCategoryList()));
  const [isOpen, setIsopen] = useState(false);

  useEffect(() => {
    if (props.linkDetails && props.linkDetails.length > 0) {
      setId(props.linkDetails[0].id);
      setDate(props.linkDetails[0].date);
      setGroupname(props.linkDetails[0].groupname);
      setUrlname(props.linkDetails[0].urlname);
      setDescription(props.linkDetails[0].description);
      setCategory(props.linkDetails[0].category);
      setHouse_id(location.state.house_id);
    }
  }, [props.linkDetails]);

  const handleSubmit = () => {
    let data = {
      house_id: house_id,
      id: id,
      date: date,
      groupname: groupname,
      urlname: urlname,
      description: description,
      category: category,
    };
    if (validate) {
      props.addLink(data);
      props.history.push({
        pathname: `/link-list/${house_id}`,
        state: { house_id: house_id },
      });
    }
  };

  const handleDelete = () => {
    let data = {
      id: id,
      house_id: house_id,
    };
    props.deleteLink(data);
    props.history.push({
      pathname: `/link-list/${house_id}`,
      state: { house_id: house_id },
    });
  };

  const validate = () => {
    const errors = [];
    if (groupname.length === 0) {
      errors.push('Group name cannot be empty.');
    }
    if (urlname.length === 0) {
      errors.push('URl cannot be empty.');
    }
    if (description.length === 0) {
      errors.push('Description cannot be empty.');
    }
    if (category.length === 0) {
      errors.push('Category cannot be empty.');
    }
    if (date.length === 0) {
      errors.push('Date cannot be empty.');
    }
    if (errors.length > 0) {
      errors.forEach(element => {
        NotificationManager.error('Error Message', element);
      });
      return false;
    }
    return true;
  };

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Links</span>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <div className="row pb-2">
            <div className="col-md-3"></div>
            <div className="col-md-6 house-form pt-25">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Date" className="req">
                      Date
                    </label>
                    <input
                      type="date"
                      placeholder="Date"
                      value={date}
                      onChange={e => setDate(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Date" className="req">
                      Group
                    </label>
                    <input
                      type="text"
                      placeholder="Group"
                      value={groupname}
                      onChange={e => setGroupname(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="text" className="req">
                      Url
                    </label>
                    <input
                      type="text"
                      placeholder="Url"
                      value={urlname}
                      onChange={e => setUrlname(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="Link Description" className="req">
                      Url Description
                    </label>
                    <input
                      type="text"
                      placeholder="Description"
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="status" className="req">
                      Category
                    </label>
                    <select
                      className="form-control"
                      value={category}
                      onChange={e => setCategory(e.target.value)}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      {categoryList.map(c => {
                        return <option value={c}>{c}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>

      <div className="row inner-footer">
        <div className="col-md-3">
          <LinkButton
            to={{
              pathname: `/link-list/${house_id}`,
              state: { house_id: house_id },
            }}
            className="btn btn-secondary btn-sm"
            role="button"
          >
            <span className="glyphicon glyphicon-backward"> </span> Back
          </LinkButton>
          {id &&
            <button className="btn btn-secondary btn-sm" onClick={() => setIsopen(true)}>

              <span className="glyphicon glyphicon-trash"> </span> Delete Entry
            </button>}
          {isOpen === true && <ConfirmModal title="Confirm`" content="Are you sure you want to delete this link ?" confirmModal={handleDelete} closeModal={setIsopen}></ConfirmModal>}
        </div>
        <div className="col-sm-6" align="center">
          <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
            Save
          </button>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  linkDetails: state.Link.linkDetails.data,
  isLoading: state.Spinner.isLinkLoading,
});

const mapDispatchToProps = {
  addLink,
  deleteLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(Link);
