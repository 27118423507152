import { Insurance } from '../../api/api';
import {
  ADD_INSURANCE,
  GET_INSURANCE,
  GET_SINGLE_INSURANCE,
  DELETE_INSURANCE,
  INSURANCE_SPINNER,
} from '../actionTypes';
import { NotificationManager } from 'react-notifications';
import { ShowSpinner, HideSpinner } from '../commondispatch';

export const addInsurance = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INSURANCE_SPINNER);
    await Insurance.addInsurance(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: ADD_INSURANCE,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, INSURANCE_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INSURANCE_SPINNER);
        throw error;
      });
  };
};

export const getInsurance = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INSURANCE_SPINNER);
    await Insurance.getInsurance(data)
      .then(res => {
        if ((res && res.status === 200) || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_INSURANCE,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, INSURANCE_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INSURANCE_SPINNER);
        throw error;
      });
  };
};

export const getSingleInsurance = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INSURANCE_SPINNER);
    await Insurance.getSingleInsurance(data)
      .then(res => {
        if ((res && res.status === 200) || res.status === 404 || res.status === 422) {
          var data = {
            type: GET_SINGLE_INSURANCE,
            payload: res,
          };
          dispatch(data);
        }
        HideSpinner(dispatch, INSURANCE_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INSURANCE_SPINNER);
        throw error;
      });
  };
};

export const deleteInsurance = data => {
  return async dispatch => {
    ShowSpinner(dispatch, INSURANCE_SPINNER);
    await Insurance.deleteInsurance(data)
      .then(res => {
        if (res.status === 200 || res.status === 404 || res.status === 422) {
          var data = {
            type: DELETE_INSURANCE,
            payload: res,
          };
          dispatch(data);
          NotificationManager.success('Success Message', res.statusText);
        } else {
          NotificationManager.error('Error Message', res.statusText);
        }
        HideSpinner(dispatch, INSURANCE_SPINNER);
      })
      .catch(error => {
        HideSpinner(dispatch, INSURANCE_SPINNER);
        throw error;
      });
  };
};

export const resetInsuranceDetails = () => {
  return dispatch => {
    var data = {
      type: GET_SINGLE_INSURANCE,
      payload: { data: null },
    };
    dispatch(data);
  };
};

