import React from 'react';
import '../../style/Contact.css';
import { Link } from 'react-router-dom';
import { getContactById, resetContactDetails } from '../../store/Actions/contact';
import { connect } from 'react-redux';
import Table from '../../Reusable/Table';
import LoadingSpinner from '../../Reusable/LoadingSpinner';
import { Util, InfoLinks } from '../../Datamanipulation/Util';

const ContactList = props => {
  let house_id = props.location.state.house_id ? props.location.state.house_id : '';
  const header = [
    'Company',
    'Contact Person',
    'Mobile No.',
    'Landline',
    'Email',
    'URL',
    'Address',
    'Group',
  ];

  var columns = [
    {
      name: 'Company',
      selector: 'companyname',
      sortable: true,
      cell: row => (
        <Link
          data-tag="allowRowEvents"
          role="link"
          to={{ pathname: `/contact-form/${house_id}`, state: { house_id: house_id } }}
        >
          {row.companyname}
        </Link>
      ),
    },
    { name: 'Contact Person', selector: 'contactperson', sortable: true },
    {
      name: 'Mobile No.',
      selector: 'phone1',
      sortable: true,
      cell: row => (row.phonetype1 === 'Mobile' ? <span>{row.phone1}</span> : '-'),
    },
    {
      name: 'Landline No.',
      selector: 'phone1',
      sortable: true,
      cell: row => (row.phonetype1 === 'Landline' ? <span>{row.phone1}</span> : '-'),
    },
    { name: 'Email', selector: 'email', sortable: true },
    { name: 'URL', selector: 'url', sortable: true },
    { name: 'Address', selector: 'address', sortable: true },
    // {
    //     name: 'Group',
    //     selector: 'groupname',
    //     sortable: true,
    //     cell: row => row.groupname.split("&")[1]
    // }
  ];

  var data = [];
  if (props.contacts) {
    data = props.contacts;
  }

  const addNewContact = () => {
    props.resetContactDetails();
    props.history.push({
      pathname: `/contact-form/${house_id}`,
      state: { house_id: house_id },
    });
  };

  return (
    <div className="container-fluid contact">
      {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Contacts</span>
        <i
          className="glyphicon glyphicon-info-sign btn-sm info-logo"
          data-toggle="modal"
          data-target="#exampleModal"
          onClick={() => Util.openInNewTab(InfoLinks.Contacts)}
        ></i>
      </div>
      <div className="inner-container">
        <div className="inner-form">
          <Table
            url={'/contact-form'}
            data={data}
            columns={columns}
            header={header}
            getSingleData={props.getContactById}
            tableId={'contact' + house_id}
            house_id={house_id}
          />
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-10"></div>
        <div className="col-sm-2 right-button">
          <button className="btn btn-secondary btn-sm" onClick={addNewContact}>
            <span className="glyphicon glyphicon-plus"> </span> Add New Contact
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  contacts: state.Contact.contacts.data,
  isLoading: state.Spinner.isContactLoading,
});

const mapDispatchToProps = {
  getContactById,
  resetContactDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
