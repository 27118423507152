import React, { useState, useEffect } from 'react';
import { Util } from '../../Datamanipulation/Util';
import { getAccount, changePassword } from '../../store/Actions/Account';
import { connect } from 'react-redux';
import FormErrors from '../../Reusable/FormErrors';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from '../../Reusable/LoadingSpinner';

const ChangePassword = props => {
  const fieldErrors = {
    OldPassword: '',
    NewPassword: '',
    ConfirmPassword: '',
  };

  const [id, setId] = useState();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user, setUser] = useState(Util.getLoggedinUser());
  const [formErrors, setFormErrors] = useState(fieldErrors);
  const history = useHistory();

  const handleSubmit = () => {
    const hashOldPassword = Buffer.from(oldPassword, 'utf8').toString('base64');
    const hashNewPassword = Buffer.from(newPassword, 'utf8').toString('base64');
    let data = {
      id: id,
      oldPassword: hashOldPassword,
      newPassword: hashNewPassword,
    };

    let valid = verfiyData(data);
    if (valid) {
      props.changePassword(data).then(res => {
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      });
    }
  };

  const verfiyData = data => {
    let formValid = true;
    if (!oldPassword || oldPassword.length < 6) {
      formValid = false;
      fieldErrors.OldPassword = ' is empty or too short';
    }
    if (!newPassword || newPassword.length < 6) {
      formValid = false;
      fieldErrors.NewPassword = ' is empty or too short';
    }
    if (!confirmPassword || confirmPassword.length < 6) {
      formValid = false;
      fieldErrors.ConfirmPassword = ' is empty or too short';
    }
    if (newPassword !== confirmPassword) {
      formValid = false;
      fieldErrors.ConfirmPassword = ' confirm and new password not matching';
    }
    setFormErrors(fieldErrors);
    return formValid;
  };

  useEffect(() => {
    if (props.accountStatus === 200) {
      localStorage.clear();
      history.push('/');
      window.location.reload();
    }
    if (props.accountDetails && props.accountDetails.length > 0) {
      setId(props.accountDetails[0].id);
    } else {
      let data = {
        id: user['id'],
      };
      props.getAccount(data);
    }
  }, [props.accountDetails, props.accountStatus]);

  return (
    <div className="container-fluid contact">
    {props.isLoading === true && <LoadingSpinner />}
      <div className="inner-container-title">
        <span>Chaange Password</span>
      </div>
      <div className="inner-container full">
        <div className="inner-form">
          <div className="row">
            <div className="col-sm-3"></div>
            <form>
              <div className="col-sm-6 row">
                <div className="col-sm-12">
                  <div className="form-group inputGroup">
                    <label htmlFor="name" className="req">
                      Old Password
                    </label>
                    <input
                      type="password"
                      placeholder="Old Password"
                      className="form-control"
                      value={oldPassword}
                      required="required"
                      onChange={e => setOldPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group inputGroup">
                    <label htmlFor="email" className="req">
                      New Password
                    </label>
                    <input
                      type="password"
                      placeholder="New Password"
                      value={newPassword}
                      required="required"
                      onChange={e => setNewPassword(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group inputGroup">
                    <label htmlFor="email" className="req">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      required="required"
                      onChange={e => setConfirmPassword(e.target.value)}
                      className="form-control"
                    />
                  </div>
                </div>
                <FormErrors formErrors={formErrors} />
              </div>
            </form>
            <div className="col-sm-3"></div>
          </div>
        </div>
      </div>
      <div className="row inner-footer">
        <div className="col-sm-12" align="center">
          <button className="btn btn-primary btn-sm" onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  accountDetails: state.Account.accountDetails.data,
  accountStatus: state.Account.status,
  isLoading: state.Spinner.isUserLoading,
});

const mapDispatchToProps = {
  getAccount,
  changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
